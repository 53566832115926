import { useLayoutEffect, useState } from "react";
import { getMobileOperatingSystem } from "../../helpers/get-mobile-os";
import "./download-app.styles.scss";

const DownloadApp = () => {
  const [errorMessage, setErrorMessage] = useState("");
  useLayoutEffect(() => {
    const deviceOS = getMobileOperatingSystem();
    if (deviceOS === "Android") {
      window.location.replace(process.env.REACT_APP_GOOGLE_PLAY_URL!);
    } else if (deviceOS === "iOS") {
      window.location.replace(process.env.REACT_APP_APP_STORE_URL!);
    } else {
      setErrorMessage(
        "Could not detect your mobile OS you should open this link from a mobile if you are not."
      );
    }
  }, []);
  return (
    <div className="download-app">
      {errorMessage.length > 0 ? (
        <span className="download-app-error">{errorMessage}</span>
      ) : (
        "redirecting..."
      )}
    </div>
  );
};

export default DownloadApp;
