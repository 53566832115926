import { FC, ReactNode } from "react";
import { ReactComponent as Ellipse1 } from "../../assets/Ellipse 1.svg";
import { ReactComponent as Ellipse2 } from "../../assets/Ellipse 2.svg";
import { ReactComponent as Ellipse3 } from "../../assets/Ellipse 3.svg";
import { ReactComponent as Ellipse4 } from "../../assets/Ellipse 4.svg";
import { ReactComponent as Ellipse5 } from "../../assets/Ellipse 5.svg";
import "./top-section-layout.styles.scss";

type Props = {
  children?: ReactNode;
  image?: string;
};

const TopSectionLayout: FC<Props> = ({ children, image }) => {
  return (
    <div className="top-section-layout">
      <div className="ellipse-1-container">
        <Ellipse1 className="ellipse-1" />
      </div>

      <div className="ellipse-5-container">
        <Ellipse5 className="ellipse-5" />
      </div>

      {image ? (
        <div className="services-image right-ellipse">
          <img src={image} alt="services" />
        </div>
      ) : (
        <>
          <div className="right-ellipse ellipse-4-container">
            <Ellipse4 className="ellipse-4 " />
          </div>

          <div className="right-ellipse ellipse-2-container">
            <Ellipse2 className="ellipse-2" />
          </div>

          <div className="right-ellipse ellipse-3-container">
            <Ellipse3 className="ellipse-3 " />
          </div>
        </>
      )}

      <div className="top-section-layout-custom-content">{children}</div>
    </div>
  );
};

export default TopSectionLayout;
