import image1 from "../../assets/our-mission-1.png";
import image2 from "../../assets/our-mission-2.png";
import image3 from "../../assets/our-mission-3.png";
import image4 from "../../assets/our-mission-4.png";
import image5 from "../../assets/our-mission-5.png";
import image6 from "../../assets/our-mission-6.png";
import image7 from "../../assets/our-mission-7.png";
import image8 from "../../assets/our-mission-8.png";

import "./our-mission-section.styles.scss";

const OurMissionSection = () => {
  return (
    <div className="our-mission-section">
      <div className="left-side-images">
        <img className="image-1" src={image1} alt="organizing tasks" />
        <img className="image-2" src={image2} alt="working on laptop" />
      </div>
      <div className="right-side-images">
        <div className="our-mission">
          <div className="our-mission-left">
            <h2 className="our-mission-title">Our Mission</h2>
            <p>
              DClick's mission is to create and provide innovative software
              solutions that meet the needs of our customers. This involves
              developing software that is user-friendly, reliable, and
              efficient, while also being scalable and flexible enough to adapt
              to changing business requirements.
            </p>
            <img className="image-6" src={image6} alt="people planning" />
          </div>
          <img className="image-7" src={image7} alt="people working" />
        </div>

        <img
          className="image-4"
          src={image4}
          alt="two people giving high five"
        />
        <img className="image-5" src={image5} alt="people working" />
      </div>
      <div className="our-vision">
        <img
          className="image-3"
          src={image3}
          alt="people having a discussion"
        />
        <div className="our-vision-image-container">
          <h2>Our Vision</h2>
          <p>
            DClick's vision is to be a leading provider of software solutions in
            its respective industry, recognized for its cutting-edge technology,
            exceptional customer service, and commitment to innovation. This
            involves constantly pushing the boundaries of what is possible with
            software development, staying ahead of industry trends and advances,
            and building strong relationships with customers and partners alike.
            Ultimately, the goal is to help businesses improve their operations,
            increase efficiency, and achieve their strategic objectives through
            the power of software.
          </p>
        </div>
        <img className="image-8" src={image8} alt="people working" />
      </div>
    </div>
  );
};

export default OurMissionSection;
