import TopSectionLayout from "../../components/top-section-layout/top-section-layout.component";
import consulting from "../../assets/consulting.jpg";
import consulting2 from "../../assets/cosulting2.jpeg";
import consulting3 from "../../assets/consulting3.png";

import CheckPoint from "../../components/Point";
import { EnvelopeIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/24/solid";
import PartnerCard from "../../components/PartnerCard";
import { partners } from "./data";
const Consulting = () => {
  return (
    <div className="bg-[#fff]">
      <TopSectionLayout>
        <div>
          <div className="flex flex-col justify-center items-center mx-auto text-center text-white pb-4 mt-[-40px]">
            <h3 className="text-placeholder-text lg:text-3xl">
              Our <span className="text-white">services</span> and consulting
            </h3>
            <p className="lg:text-[18px] px-4 lg:w-[65%] text-placeholder-text pt-[12px]">
              Discover how our tech solutions can transform your business.
              Explore our features and take your business to new heights.
            </p>
          </div>
        </div>
      </TopSectionLayout>
      <div className=" px-5 md:px-[48px]">
        <div className="relative mt-[-145px] md:px-[48px] mx-auto mb-6">
          <div className="flex flex-col lg:flex-row justify-between gap-7">
            <div className="w-full lg:w-[399px]">
              <div className="rounded-xl bg-[#F5F5F5] p-10">
                <h4 className="font-Crimson text-lg font-[500] pb-[22px]">
                  Categories
                </h4>
                <ul className="flex flex-col gap-3">
                  <CheckPoint text="Digital Planning" />
                  <CheckPoint text="Consulting Services" />
                  <CheckPoint text="Strategy StartUp" />
                  <CheckPoint text="Financial Business" />
                  <CheckPoint text="Business Solution" />
                </ul>
              </div>
              <div className="rounded-xl bg-red p-10 text-white mt-5">
                <h4 className="font-Crimson text-lg font-[500]">
                  Need Any Help?
                </h4>
                <p className="text-[15.75px] font-[400] pb-[32px]">
                  Need Any Help, Call Us 24/7 For Support
                </p>
                <ul className="flex flex-col gap-3">
                  <li className="flex items-center gap-4 mb-5">
                    <div className="rounded-full border-[1px] w-[50px] h-[50px] border-white flex items-center justify-center">
                      <EnvelopeIcon width={16} color="white" />
                    </div>
                    <div>
                      <p className="text-[13.56px]">Mail Us</p>
                      <a href="mailto:Info@dclickltd.com">Info@dclickltd.com</a>
                    </div>
                  </li>
                  <li className="flex items-center gap-4 mb-5">
                    <div className="rounded-full border-[1px] w-[50px] h-[50px] border-white flex items-center justify-center">
                      <MapPinIcon width={16} color="white" />
                    </div>
                    <div>
                      <p className="text-[13.56px]">Office Address</p>
                      <p>Birmingham, UK</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <img src={consulting} className="rounded-3xl w-full" alt="" />
              <div className="content mt-[25px]">
                <h3 className="font-Crimson text-xl">
                  Your Business, Our Expertise
                </h3>
                <p className="text-subText text-[15.75px]">
                  We provide the E2E implementation services for front, middle
                  and back-office operations, to transform customer experience,
                  supply chain management, project operations, human resources,
                  financial and analytics through our connected implementations
                  teams across the UK and Europe, our implementation services
                  not only starting from the implementation but setting up the
                  full digital transformation strategy including the process
                  mapping development and enhancement using the target operating
                  model that aims to drive the implementation in a structured
                  way:
                </p>
                <div className="text-subText text-[15.75px]">
                  <li>Vision and Strategy</li>
                  <li>Target Operating Model</li>
                  <li>Business Process</li>
                  <li>Capabilities (People)</li>
                  <li>Business Technology </li>
                  <li>Reporting and Insights Governance</li>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-11/12 xl:px-[67px] mx-auto mb-4 mt-8 lg:mt-20">
          <div className="flex flex-col lg:flex-row justify-between items-center lg:gap-[122px]">
            <div className="w-[100%] lg:w-[43%] 2xl:w-[50%]">
              <img
                src={consulting2}
                className="w-full rounded-xl"
                alt="consulting"
              />
            </div>
            <div className="lg:w-[52%] w-full mt-10 lg:mt-0">
              <span className="bg-[#EFEEE7] uppercase px-2 text-mainText text-xs rounded">
                Process
              </span>
              <h3 className="text-primary text-lg xl:text-xxl font-[500] leading-[56.4px] py-3">
                <span className="text-red">Agile</span> methodology
              </h3>
              <p className="md:w-3/4 2xl:w-2/4 text-[18px] leading-[31.5px] text-subText mb-5">
                The agile methodology is a flexible approach to project, which
                we use to deliver our solutions.
              </p>
              <div className="mb-[38px] text-subText text-[15px]">
                <ul className="flex flex-col gap-[10px] text-primary">
                  <CheckPoint
                    text="Requirements diagnosis"
                    width={30}
                    className="bg-[#EFEEE7] p-1 rounded-lg text-primary"
                  />
                  <CheckPoint
                    text="Solution design and validation"
                    width={30}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                  <CheckPoint
                    text="Data migration and opening balance strategy, planning and execution"
                    width={30}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                  <CheckPoint
                    text="User testing acceptance and training"
                    width={30}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                  <CheckPoint
                    text="Go live"
                    width={30}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                  <CheckPoint
                    text="Hyper Care"
                    width={30}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                  <CheckPoint
                    text="Support"
                    width={30}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                </ul>
              </div>
              <a href="mailto:Info@dclickltd.com">
                <button className="bg-red text-white text-[14px] rounded-xl py-[18px] px-6">
                  Learn more
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="w-11/12 xl:px-[67px] mx-auto my-10 lg:my-20">
          <div className="flex flex-col lg:flex-row justify-between items-center lg:gap-[122px]">
            <div className="lg:w-[52%] w-full mt-10 lg:mt-0 order-2 lg:order-1">
              <span className="bg-[#EFEEE7] uppercase px-2 text-mainText text-xs rounded">
                Experience
              </span>
              <h3 className="text-primary text-lg xl:text-xxl font-[500] leading-[56.4px] py-3 xl:w-[90%] 2xl:w-[80%]">
                Team <span className="text-red">experience</span>
              </h3>
              <p className="lg:w-[70%] 2xl:w-[59%] text-[18px] leading-[31.5px] text-subText mb-5">
                Our team is experienced in many areas, industries, and
                technologies.
              </p>
              <h3 className="font-Crimson text-lg">Microsoft Dynamics:</h3>
              <p>
                Our experienced teams have more than 12 years’ experience as an
                average for our consultants who used to work with BIG 4 and
                Microsoft with experience in the UK, Europe, USA, and Arabic
                Gulf.
              </p>
              <div className="mb-[38px] text-subText text-[15px]">
                <ul className="list-outside hover:list-inside">
                  <CheckPoint
                    text=" Microsoft Dynamics 365 FO"
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-lg text-primary"
                  />
                  <CheckPoint
                    text="Microsoft Dynamics 365 CE"
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                  <CheckPoint
                    text="Microsoft Dynamics 365 BC"
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />{" "}
                  <CheckPoint
                    text=" Microsoft Power Apps"
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                  <CheckPoint
                    text="Microsoft Power BI"
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                </ul>
              </div>
              <h3 className="font-Crimson text-lg">Oracle:</h3>
              <p>
                Our experienced teams have more than 15 years in Oracle business
                applications as an average experience for our solution
                architects and solution consultants who used to work with BIG-4
                and Oracle central hubs for the implementation and support in
                the UK, Europe.
              </p>
              <div className="mb-[38px] text-subText text-[15px]">
                <ul className="list-outside hover:list-inside">
                  <CheckPoint
                    text="Oracle Fusion ERP"
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-lg text-primary"
                  />
                  <CheckPoint
                    text="Oracle Fusion SCM"
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                  <CheckPoint
                    text="Oracle Fusion HCM "
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                  <CheckPoint
                    text="Oracle Fusion CX "
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                  <CheckPoint
                    text="Oracle NetSuit"
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                </ul>
              </div>
              <h3 className="font-Crimson text-lg">SAP:</h3>
              <p>
                Our experienced teams have more than 17 years in SAP business
                applications, our solution architects used to work with BIG-4
                and SAP central hubs for the implementation and support in the
                UK, Europe.
              </p>
              <div className="mb-[38px] text-subText text-[15px]">
                <ul className="list-outside hover:list-inside">
                  <CheckPoint
                    text="SAP S/4HANA Cloud"
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                  <CheckPoint
                    text=" SAP SuccessFactors"
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                  <CheckPoint
                    text="SAP Ariba"
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                  <CheckPoint
                    text="SAP Concur"
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                  <CheckPoint
                    text="SAP Business One"
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                  <CheckPoint
                    text="SAP Business ByDesign"
                    width={20}
                    className="bg-[#EFEEE7] p-1 rounded-xl text-primary"
                  />
                </ul>
              </div>

              <a href="mailto:Info@dclickltd.com">
                <button className="bg-red text-white text-[14px] rounded-xl py-[18px] px-6">
                  Learn more
                </button>
              </a>
            </div>
            <div className="w-[100%] lg:w-[43%] 2xl:w-[50%] order-1 lg:order-2">
              <img
                src={consulting3}
                className="w-full rounded-xl"
                alt="consulting"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#EFEEE7] py-[90px] px-[100px] text-center">
        <span className="bg-[#fff] uppercase px-2 text-mainText text-xs rounded">
          Our partners
        </span>
        <h3 className="text-primary text-lg xl:text-xxl mx-auto font-[500] leading-[56.4px] py-3 xl:w-[90%] 2xl:w-[80%]">
          Partners with
          <span className="text-red"> sustainable </span>brands
        </h3>
        <p className="lg:w-[70%] 2xl:w-[45%] mx-auto text-[18px] leading-[31.5px] text-subText text-center mb-5">
          Empower your creativity and craft stunning sites effortlessly while
          enjoying real-time customer support at every step.
        </p>
        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4 mt-14">
          {partners.map((partner, index) => (
            <PartnerCard
              key={index}
              image={partner.image}
              title={partner.title}
              body={partner.body}
            />
          ))}
        </div>
      </div>
      <div className="bg-[#fff] py-[90px] px-[100px] text-center">
        <span className="bg-[#EFEEE7] uppercase px-2 text-mainText text-xs rounded">
          Start today
        </span>
        <h3 className="text-primary text-lg xl:text-xxl mx-auto font-[500] leading-[56.4px] py-3 xl:w-[90%] 2xl:w-[80%]">
          Ready to get started?
          <br /> Take the
          <span className="text-red"> next step </span>
        </h3>
        <p className="lg:w-[35%] 2xl:w-[40%] mx-auto text-[18px] leading-[31.5px] text-subText text-center mb-5">
          Experience unparalleled service, cutting-edge technology, and
          dedicated support.
        </p>
        <a href="mailto:Info@dclickltd.com">
          <button className="bg-red text-white text-[14px] rounded-xl py-[18px] px-6">
            Get started now
          </button>
        </a>
      </div>
    </div>
  );
};

export default Consulting;
