import { useRef } from "react";
import { Outlet } from "react-router";
import { Link, NavLink } from "react-router-dom";
import Footer from "../../components/footer/footer.component";
import logo from "../../assets/dclick-logo.png";
import "./navigation.styles.scss";

const Navigation = () => {
  const navigationLinksRef = useRef<HTMLDivElement>(null);
  const toggleMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.currentTarget.classList.toggle("active");
    navigationLinksRef.current?.classList.toggle("active");
  };
  return (
    <>
      <nav className='navigation'>
        <Link to='/'>
          <div className='flex  h-[105px] pl-10'>
            <img
              className='object-contain lg:w-[72px] w-[43px]'
              src={logo}
              alt='company logo'
            />
          </div>
        </Link>
        <div ref={navigationLinksRef} className='navigation-links'>
          <NavLink to='services'>
            <div>Services</div>
          </NavLink>
          <NavLink to='case-studies'>
            <div>Case studies</div>
          </NavLink>
          <NavLink to='about-us'>
            <div>About us</div>
          </NavLink>
          <NavLink to='consultation'>
            <div>Consultation</div>
          </NavLink>

          <a className='contact' href='#contact'>
            Contact
          </a>
        </div>
        <div onClick={toggleMenu} className='hamburger'>
          <span className='bar'></span>
          <span className='bar'></span>
          <span className='bar'></span>
        </div>
      </nav>
      <Outlet />
      <Footer />
    </>
  );
};

export default Navigation;
