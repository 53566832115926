import { FC } from "react";
import "./right-arrow.styles.scss";

type ArrowProps = {
  color?: string;
};

const RightArrow: FC<ArrowProps> = ({ color }) => {
  return (
    <div className="right-arrow">
      <div style={{ backgroundColor: color }} className="arrow"></div>
    </div>
  );
};

export default RightArrow;
