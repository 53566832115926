export const cardsArray = [
  
  {
    title: "Customer Focus",
    description:
      "Ensuring that the needs and expectations of customers are at the center of everything the company does.",
  },
  {
    title: "Quality",
    description:
      "A dedication to delivering products and services that are reliable, effective, and meet or exceed industry standards.",
  },
  {
    title: "Transparency",
    description:
      "An openness and honesty with customers, employees, and stakeholders about the company's practices, policies, and performance.",
  },
  {
    title: "Ethics",
    description:
      " Conducting business in a manner that is ethical, legal, and socially responsible, and treating all stakeholders with respect and fairness.",
  },
];
