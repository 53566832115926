import { useNavigate } from "react-router-dom";
import TopSectionLayout from "../../../components/top-section-layout/top-section-layout.component";
import deskBooking1 from "../../../assets/deskBooking1.png";
import deskBooking2 from "../../../assets/deskBooking2.png";
import deskBooking3 from "../../../assets/deskBooking3.png";
import deskBooking4 from "../../../assets/deskBooking4.png";
import iphone12 from "../../../assets/iPhone12.png";

const DeskBooking = () => {
  const navigate = useNavigate();

  return (
    <div className='bg-[#ebebec]'>
      <TopSectionLayout>
        <div className='hidden relative md:grid md:grid-cols-2 lg:grid-cols-3  grid-cols-1 gap-2 items-center mb-4 text-white'>
          <div className='lg:col-span-2 w-full pl-4'>
            <span className='sm:text-sm lg:text-smm pb-2 font-normal'>
              Desk Booking System
            </span>
            <h3 className='font-bold text-md lg:text-lg'>
              Your space.Your time
            </h3>
            <p className='text-smm lg:text-md lg:w-4/5 font-normal'>
              Make flexible working work for you. Find workspaces that suit your
              plans and reserve them in moments.
            </p>
          </div>
          <img
            src={iphone12}
            alt='miro img'
            className='w-[195px] h-[418px] lg:mt-12 lg:w-[267px] lg:h-[538px]  mx-auto  lg:col-span-1'
          />
        </div>
      </TopSectionLayout>

      <div className='md:hidden relative mt-[-445px] w-11/12 lg:w-4/5 mx-auto mb-20'>
        <div className='relative md:grid md:grid-cols-2 lg:grid-cols-3  grid-cols-1 gap-2 items-center mb-4 text-white'>
          <div className='pb-8 mg:pb-0 lg:col-span-2 w-full pl-4'>
            <span className='sm:text-sm lg:text-smm pb-2 font-normal'>
              Desk Booking System
            </span>
            <h3 className='font-bold text-md lg:text-lg'>
              Your space.Your time
            </h3>
            <p className='text-smm lg:text-md lg:w-4/5 font-normal'>
              Make flexible working work for you. Find workspaces that suit your
              plans and reserve them in moments.
            </p>
          </div>
          <img
            src={iphone12}
            alt='miro img'
            className='w-[195px] h-[418px] lg:mt-12 lg:w-[267px] lg:h-[538px]  mx-auto  lg:col-span-1'
          />
        </div>
      </div>

      <section className='w-11/12 lg:w-4/5 my-10 mx-auto '>
        <div className='grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 items-center mb-4 gap-6'>
          <div className=' md:col-span-1 lg:col-span-2 lg:order-1 order-2 ml-12'>
            <span className='lg:text-sm text-mainText font-normal'>
              Workspace reservation
            </span>
            <h2 className='text-mainText text-lg font-medium mb-[22px] pt-[22px]'>
              Your simple self-serve tool.
            </h2>
            <p className='text-subText lg:text-smm font-normal text-sm'>
              we offer a catering service to complement your workspace
              reservation. From breakfast and coffee services to lunch and snack
              options, our catering service ensures that you and your team are
              fueled and ready to work efficiently. You can select from a range
              of menu options, customize your order, and have it delivered
              directly to your workspace.
            </p>
          </div>
          <img
            src={deskBooking1}
            alt='miro img'
            className='w-[335px] h-[335px] md:w-[468px] md:h-[459px]  order-1 lg:order-2 md:col-span-1 mx-auto'
          />
        </div>

        <div className='grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 items-center mb-4 gap-6'>
          <img
            src={deskBooking2}
            alt='miro img'
            className='w-[335px] h-[335px] md:w-[468px] md:h-[459px]   md:col-span-1 mx-auto'
          />
          <div className=' md:col-span-1 lg:col-span-2  ml-12'>
            <span className='lg:text-sm text-mainText font-normal'>
              Find colleagues
            </span>
            <h2 className='text-mainText text-lg font-medium mb-[22px] pt-[22px]'>
              Find your co-workers fast.
            </h2>
            <p className='text-subText lg:text-smm font-normal text-sm'>
              Even though your colleagues may work different hours than you, App
              can help them bond. Through the platform you can search for
              colleagues and find out where they work from, making your
              collaboration easier and more productive.
            </p>
          </div>
        </div>

        <div className='grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 items-center mb-4 gap-6'>
          <div className=' md:col-span-1 lg:col-span-2 lg:order-1 order-2 ml-12'>
            <span className='lg:text-sm text-mainText font-normal'>
              Flexible work force
            </span>
            <h2 className='text-mainText text-md lg:text-lg font-medium mb-[22px] pt-[22px]'>
              Organize your time better.
            </h2>
            <p className='text-subText lg:text-smm font-normal text-sm'>
              On the days you're in the office, App makes sure you get the most
              out of your day. You can set your status so employees can find you
              and reserve additional spaces like meeting rooms, desks, and more
              to seamlessly coordinate your day.
            </p>
          </div>
          <img
            src={deskBooking3}
            alt='miro img'
            className='w-[335px] h-[335px] md:w-[498px] md:h-[469px]  order-1 lg:order-2 md:col-span-1 mx-auto'
          />
        </div>
      </section>

      <section className='w-11/12 lg:w-4/5 mx-auto py-12  pb-[100px]'>
        <h5 className='text-center pb-1 lg:text-lg text-md font-medium text-black'>
          Add-ons
        </h5>
        <h3 className='text-center pb-6 lg:text-xl text-lg  font-bold text-black'>
          Additional hardware
        </h3>
        <div className='flex justify-center w-full mx-auto '>
          <div className='rounded-2xl bg-white pb-5 w-full'>
            <img
              src={deskBooking4}
              alt='dClick logo'
              className='h-[313px] w-full  object-cover md:object-none  mb-6 rounded-t-2xl bg-[#00000033]'
            />
            <div className='px-2'>
              <h4 className='text-md font-medium text-mainText'>
                Desk screens
              </h4>
              <p className='text-sm text-subText font-normal py-[12px]'>
                Enhance your workspace experience with desk screens.
              </p>
              <button
                className='text-white bg-red rounded-3xl px-[16px] py-[10px] text-sm font-medium w-[150px]'
                onClick={() => {
                  navigate("/case-studies/desk-screens");
                  window.scrollTo(0, 0);
                }}>
                Explore
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DeskBooking;
