import Card from "../card/card.component";
import { cardsArray } from "./data";
import "./product-help-section.styles.scss";

const ProductHelpSection = () => {
  return (
    <div className="product-help">
      <div className="container">
        <p>More than custom software development</p>
        <h2>How can we help your product?</h2>
        <div className="product-help-card-list">
          {cardsArray.map(({ description, icon, title, id }) => (
            <Card
              key={id}
              cardDescription={description}
              cardTitle={title}
              iconFileName={icon}
              showButton
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductHelpSection;
