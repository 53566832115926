import { useNavigate } from "react-router-dom";
import TopSectionLayout from "../../../components/top-section-layout/top-section-layout.component";
import ipadPro from "../../../assets/iPadPro.png";
import wayFinding from "../../../assets/wayfinder.png";
import roomCard from "../../../assets/roomCard.png";
import roomCard1 from "../../../assets/roomCard1.png";
import roomCard2 from "../../../assets/roomCard2.png";
import roomCard3 from "../../../assets/roomCard3.png";

const MeetingRoomBooking = () => {
  const navigate = useNavigate();

  const ADDITIONAL_HARDWARE = [
    {
      title: "Meeting room screens",
      description:
        "Enhance your workspace experience with meeting room screens.",
      image: ipadPro,
      link: "/",
    },
    {
      title: "Wayfinder",
      description: "Enhance your workspace experience with a Wayfinder.",
      image: wayFinding,
      link: "/",
    },
  ];

  return (
    <div className='bg-[#ebebec]'>
      <TopSectionLayout>
        <div className='hidden relative md:grid md:grid-cols-2 lg:grid-cols-3  grid-cols-1 gap-2 items-center mb-4 text-white'>
          <div className='lg:col-span-2 w-full pl-4'>
            <span className='sm:text-sm lg:text-smm pb-2 font-normal'>
              Meeting Room Booking System
            </span>
            <h3 className='font-bold text-md lg:text-lg'>
              Meet your new way to collaborate.
            </h3>
            <p className='text-smm lg:text-md lg:w-4/5 font-normal'>
              d litora torquent per conubia nostra, per inceptos himenaeos.
              Praesent auctor purus luctus enim egestas, ac scelerisque ante
              pulvinar.
            </p>
          </div>
          <img
            src={roomCard}
            alt='miro img'
            className='w-[195px] h-[418px] lg:mt-12 lg:w-[267px] lg:h-[538px]  mx-auto  lg:col-span-1'
          />
        </div>
      </TopSectionLayout>

      <div className='md:hidden relative mt-[-445px] w-11/12 lg:w-4/5 mx-auto mb-20'>
        <div className='relative md:grid md:grid-cols-2 lg:grid-cols-3  grid-cols-1 gap-2 items-center mb-4 text-white'>
          <div className='pb-8 mg:pb-0 lg:col-span-2 w-full pl-4'>
            <span className='sm:text-sm lg:text-smm pb-2 font-normal'>
              Meeting Room Booking System
            </span>
            <h3 className='font-bold text-md lg:text-lg'>
              Meet your new way to collaborate.
            </h3>
            <p className='text-smm lg:text-md lg:w-4/5 font-normal'>
              d litora torquent per conubia nostra, per inceptos himenaeos.
              Praesent auctor purus luctus enim egestas, ac scelerisque ante
              pulvinar.
            </p>
          </div>
          <img
            src={roomCard}
            alt='miro img'
            className='w-[195px] h-[418px] lg:mt-12 lg:w-[267px] lg:h-[538px]  mx-auto  lg:col-span-1'
          />
        </div>
      </div>

      <section className='w-11/12 lg:w-4/5 my-10 mx-auto '>
        <div className='grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 items-center mb-4 gap-6'>
          <div className=' md:col-span-1 lg:col-span-2 lg:order-1 order-2 ml-12'>
            <span className='lg:text-sm text-mainText font-normal'>
              Meeting room booking
            </span>
            <h2 className='text-mainText text-lg font-medium mb-[22px] pt-[22px]'>
              Bring people together with ease.
            </h2>
            <p className='text-subText lg:text-smm font-normal text-sm'>
              smart office platform that allows users to book meeting rooms,
              desks, and resources directly from their mobile devices. It
              simplifies the process of finding and reserving available spaces,
              making it easy for people to come together for meetings and
              collaborative work.
            </p>
          </div>
          <img
            src={roomCard1}
            alt='miro img'
            className='w-[335px] h-[335px] md:w-[468px] md:h-[459px]  order-1 lg:order-2 md:col-span-1 mx-auto'
          />
        </div>

        <div className='grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 items-center mb-4 gap-6'>
          <img
            src={roomCard2}
            alt='miro img'
            className='w-[335px] h-[335px] md:w-[468px] md:h-[459px]   md:col-span-1 mx-auto'
          />
          <div className=' md:col-span-1 lg:col-span-2  ml-12'>
            <span className='lg:text-sm text-mainText font-normal'>
              Dynamic space management
            </span>
            <h2 className='text-mainText text-lg font-medium mb-[22px] pt-[22px]'>
              Make changes stress-free.
            </h2>
            <p className='text-subText lg:text-smm font-normal text-sm'>
              workplace experience platform that offers an intuitive mobile app
              for room booking. Users can quickly search for available rooms,
              view details such as room capacity and amenities, and make
              reservations on the go. With Teem, you can streamline the process
              of scheduling meetings and ensure a smooth experience for your
              team
            </p>
          </div>
        </div>

        <div className='grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 items-center mb-4 gap-6'>
          <div className=' md:col-span-1 lg:col-span-2 lg:order-1 order-2 ml-12'>
            <span className='lg:text-sm text-mainText font-normal'>
              Flexible work force
            </span>
            <h2 className='text-mainText text-md lg:text-lg font-medium mb-[22px] pt-[22px]'>
              Organize your time better.
            </h2>
            <p className='text-subText lg:text-smm font-normal text-sm'>
              Our mobile app allows you to easily schedule your bookings and
              organize your time more efficiently. With a flexible work force,
              it is important to have a reliable tool to stay on top of your
              appointments. Say goodbye to clunky paper calendars or tedious
              phone calls – our app gives you complete control over your
              bookings with just a few taps on your phone
            </p>
          </div>
          <img
            src={roomCard3}
            alt='miro img'
            className='w-[335px] h-[335px] md:w-[498px] md:h-[469px]  order-1 lg:order-2 md:col-span-1 mx-auto'
          />
        </div>
      </section>

      <section className='w-11/12 lg:w-4/5 mx-auto py-12  pb-[100px]'>
        <h5 className='text-center pb-1 lg:text-lg text-md font-medium text-black'>
          Add-ons
        </h5>
        <h3 className='text-center pb-6 lg:text-xl text-lg  font-bold text-black'>
          Additional hardware
        </h3>
        <div className='grid md:grid-cols-2  grid-cols-1 gap-4  justify-center w-[335px]  md:w-full mx-auto '>
          {ADDITIONAL_HARDWARE?.map((item) => {
            return (
              <div key={item?.title} className='rounded-2xl bg-white pb-5 '>
                <img
                  src={item?.image || "https://placehold.co/387x313"}
                  alt='dClick logo'
                  className='h-[313px]  w-full mb-6 rounded-t-2xl bg-[#00000033]'
                />
                <div className='px-2'>
                  <h4 className='text-md font-medium text-mainText'>
                    {item?.title}
                  </h4>
                  <p className='text-sm text-subText font-normal py-[12px]'>
                    {item?.description}
                  </p>
                  <button
                    className='text-white bg-red rounded-3xl px-[16px] py-[10px] text-sm font-medium w-[150px]'
                    onClick={() => {
                      navigate(item?.link ?? "/");
                      window.scrollTo(0, 0);
                    }}>
                    Explore
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default MeetingRoomBooking;
