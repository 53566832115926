import { useNavigate } from "react-router-dom";
import Icon from "../../components/icon/icon";
import TopSectionLayout from "../../components/top-section-layout/top-section-layout.component";
import welcomeVideo from "../../assets/caseStudies_welcome.mp4";
import logo from "../../assets/dclick-logo.png";
import iphones from "../../assets/iphones.png";
import tablet from "../../assets/tablet.png";
import iPadPro from "../../assets/iPadPro.png";
import miro from "../../assets/miro.png";
import board from "../../assets/board.png";
import scopOfWork from "../../assets/scope_of_work.png";
import code from "../../assets/code.png";
import { useState } from "react";

const CaseStudies = () => {
  const navigate = useNavigate();
  const [isPlay, setIsPlay] = useState(false);
  const PRODUCT_FEATURES = [
    {
      title: "Desk booking",
      description: "Find and book workspace anytime, anywhere with ease.",
      image: iphones,
      link: "/case-studies/desk-booking",
    },
    {
      title: "Meeting Room Booking",
      description:
        "Book meeting rooms straight from Microsoft Outlook with our Smart Calendar add-in.",
      image: iPadPro,
      link: "/case-studies/meeting-room-booking",
    },
    {
      title: "Visitor Management",
      description:
        "Welcome everyone into your workplace with a superlative end-to-end experience.",
      image: tablet,
      link: "/",
    },
  ];

  return (
    <div className='bg-[#ebebec]'>
      <TopSectionLayout>
        <div>
          <div className='flex flex-col justify-center items-center mx-auto text-center text-white pb-4 mt-[-40px]'>
            <span className='text-md pb-5'>App name</span>
            <h3 className='font-bold lg:text-lg'>
              We make your flexible office work better.
            </h3>
            <p className='lg:text-smm px-4 lg:w-2/4'>
              Our vision is to create the best workplace for you with our
              meeting room and desk booking solutions.
            </p>
          </div>

          <button
            type='button'
            className='text-white bg-red mx-auto flex justify-center items-center px-[12px] py-[12px] rounded-3xl text-sm gap-2'
            onClick={() => setIsPlay(true)}>
            <span>
              <Icon name={"play"} />
            </span>
            <span>Play Video</span>
          </button>
        </div>
      </TopSectionLayout>
      {/* image */}
      <div className='relative mt-[-145px] w-11/12 lg:w-4/5 mx-auto mb-6'>
        <video
          src={welcomeVideo}
          className='w-[334px] h-[194px]  md:w-[634px] md:h-[314px] lg:w-[1200px] lg:h-[694px]  object-fill rounded-3xl mx-auto'
          controls
          autoPlay={isPlay}
        />
      </div>
      {/* content */}
      <div className='w-11/12 lg:w-4/5 mx-auto mt-2 mb-4 lg:mt-10'>
        <div className='flex flex-col lg:flex-row lg:gap-[122px]'>
          <div className='flex flex-col lg:w-[790px] order-1 lg:order-0 '>
            <h5 className='lg:text-xl text-mainText'>
              visitor and employee management software is an efficient way for
              organizations to manage their visitors and employees while
              improving security and streamlining operations.
            </h5>
            <p className='lg:text-smm mt-3 mb-6 text-subText'>
              Visitor and employee management software is a digital solution
              that helps organizations to manage their visitors and employees.
              It allows organizations to track who enters and exits the
              premises, record their personal information, and monitor their
              activities. These software or apps are used by various industries
              such as healthcare, education, hospitality, and more.
            </p>
            <button className='mt-[16px] flex justify-center gap-[16px] text-sm font-normal border border-primary text-center w-[328px] py-2 px-4 rounded-[32px] h-[60px] items-center'>
              Show live app
            </button>
          </div>
          <div className='order-0 lg:order-1'>
            <ul className='grid grid-cols-2 lg:flex lg:flex-col lg:gap-0'>
              <li className='flex flex-col gap-2 lg:gap-[16px] pb-6'>
                <span className='text-mainText text-smm font-normal'>
                  Clint
                </span>
                <span className='text-subText text-sm font-normal'>Dclick</span>
              </li>
              <li className='flex flex-col gap-2 lg:gap-[16px] pb-6'>
                <span className='text-mainText text-smm font-normal'>
                  Location
                </span>
                <span className='text-subText text-sm font-normal'>London</span>
              </li>
              <li className='flex flex-col gap-2 lg:gap-[16px] pb-6 flex-1 w-full'>
                <span className='text-mainText text-smm font-normal'>
                  Scope
                </span>
                <span className='text-subText text-sm font-normal'>
                  Development
                </span>
                <span className='text-subText text-sm font-normal'>
                  Product Management
                </span>
                <span className='text-subText text-sm font-normal'>
                  Product Design
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <section className='w-11/12 lg:w-4/5 mx-auto lg:mt-[100px] mt-[64px] mb-[64px] lg:mb-[34px]  '>
        <h3 className='lg:text-xl font-bold text-md pb-[33px]'>
          The Challenge
        </h3>
        <p className='text-subText lg:text-smm text-sm font-normal'>
          The software should allow visitors to register their details such as
          name and email address. It should also scan Qr code. Employee
          Management, The software should also manage the entry and exit of
          employees by tracking their attendance. Access Control, The software
          should provide access control by allowing employees to swipe their ID
          cards or use biometric authentication to enter restricted areas.
          Notifications, The software should send notifications to employees
          when they have a meeting scheduled, when a visitor arrives, or when
          their leave request has been approved or denied. Reporting, The
          software should provide reports on visitor traffic, employee booking,
          and other relevant data that can be used for analysis and decision
          making.The software should be user-friendly and easy to use for both
          employees and visitors. It should also be secure, with robust data
          encryption and access controls to prevent unauthorized access.
        </p>
      </section>
      <section className='w-11/12 lg:w-4/5 mb-10 mx-auto lg:h-[398px] h-[150px]  bg-primary-dark rounded-[24px] flex justify-center items-center'>
        <img
          src={logo}
          alt='dClick logo'
          className='w-[92px] h-[112px] lg:w-[228px] lg:h-[188px]  mx-auto '
        />
      </section>
      <section className='w-11/12 lg:w-4/5 mb-10 mx-auto grid md:grid-cols-2 grid-cols-1 gap-6'>
        <img
          src={iphones}
          alt='iphones img'
          className='w-[334px] h-[262px] lg:w-[590px] lg:h-[462px]   mx-auto'
        />
        <img
          src={tablet}
          alt='tablet img'
          className='w-[334px] h-[262px] lg:w-[590px] lg:h-[462px]   mx-auto'
        />
      </section>

      <section className='w-11/12 lg:w-4/5 mb-10 mx-auto '>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-6 items-center mb-4'>
          <div className='lg:order-1 order-2'>
            <h2 className='text-mainText text-md lg:text-lg font-medium mb-7'>
              Product Discovery
            </h2>
            <p className='text-subText lg:text-smm font-normal text-sm'>
              We addressed these challenges in product discovery workshops that
              allowed us to prioritize application features and propose a
              long-term roadmap for product development. The meetings took place
              completely remotely.
            </p>
          </div>
          <div className='relative w-[334px] h-[262px] lg:w-[590px] lg:h-[462px]  mx-auto order-1 lg:order-2 '>
            <img src={miro} alt='miro img' className='w-full h-full' />
            <span className='flex justify-center content-center items-center absolute inset-0 text-[64px] lg:text-[128px] font-semibold text-white'>
              Miro
            </span>
          </div>
        </div>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-6 items-center'>
          <div className='relative w-[334px] h-[262px] lg:w-[590px] lg:h-[462px]  mx-auto order-1 md:order-2 '>
            <img src={board} alt='board img' className='w-full h-full' />
            <div className='flex flex-col justify-center content-center items-center absolute inset-0 text-[64px] lg:text-[128px] font-semibold text-white'>
              Board
              <span className='flex text-[40px] font-normal'>Devops</span>
            </div>
          </div>

          <div className='lg:order-2 order-1'>
            <p className='text-subText lg:text-smm font-normal text-sm'>
              We empathize with future users by pointing out their problems and
              advantages. Later, in the ideation phase, we proposed solutions on
              how to address these needs and, more importantly, we verified our
              assumptions in user interviews.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className='w-11/12 lg:w-4/5 mx-auto mb-10 mt-[100px]'>
          <h3 className='text-center pb-5 font-bold text-md lg:text-xl'>
            Scope of work
          </h3>
          <p className='text-subText lg:text-smm font-normal text-sm'>
            Our task was to create an efficient management platform that allows
            managers and employees of sustainable fish farms to plan and manage
            their work in different locations and to communicate with each other
            via different devices.
          </p>
        </div>
        <img
          src={scopOfWork}
          alt='scop Of Work img'
          className='h-[190px] w-11/12 md:w-full md:h-[400px] lg:h-[546px]  lg:mx-0 mx-auto mb-6'
        />
      </section>
      <section className='bg-primary'>
        <div className='w-11/12 lg:w-4/5 mx-auto mb-10 text-white pt-[50px] lg:pb-[50px] pb-[30px]'>
          <h2 className='text-md font-medium pb-7'>The Solution</h2>
          <p className='lg:text-smm text-sm font-normal text-placeholder-text '>
            The software should allow visitors to register their details such as
            name and email address. It should also scan Qr code. Employee
            Management, The software should also manage the entry and exit of
            employees by tracking their attendance. Access Control, The software
            should provide access control by allowing employees to swipe their
            ID cards or use biometric authentication to enter restricted areas.
            Notifications, The software should send notifications to employees
            when they have a meeting scheduled, when a visitor arrives, or when
            their leave request has been approved or denied. Reporting, The
            software should provide reports on visitor traffic, employee
            booking, and other relevant data that can be used for analysis and
            decision making.The software should be user-friendly and easy to use
            for both employees and visitors. It should also be secure, with
            robust data encryption and access controls to prevent unauthorized
            access.
          </p>
        </div>
      </section>
      <section className='w-11/12 lg:w-4/5 mx-auto py-12  pb-[100px]'>
        <h3 className='text-center pb-6 lg:text-xl text-md font-medium text-black'>
          Product Feature
        </h3>
        <div className='grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-4  justify-center w-[335px]  md:w-full mx-auto '>
          {PRODUCT_FEATURES?.map((product) => {
            return (
              <div key={product?.title} className='rounded-2xl bg-white pb-5 '>
                <img
                  src={product?.image || "https://placehold.co/387x313"}
                  alt='dClick logo'
                  className='h-[313px]  w-full   mb-6 rounded-t-2xl bg-[#00000033]'
                />
                <div className='px-2'>
                  <h4 className='text-md font-medium text-mainText'>
                    {product?.title}
                  </h4>
                  <p className='text-sm text-subText font-normal py-[12px]'>
                    {product?.description}
                  </p>
                  <button
                    className='text-white bg-red rounded-3xl px-[16px] py-[10px] text-sm font-medium w-[150px]'
                    onClick={() => {
                      navigate(product?.link ?? "/");
                      window.scrollTo(0, 0);
                    }}>
                    Explore
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <section className='w-11/12 lg:w-4/5 mx-auto py-12 pb-[100px] grid md:lg:grid-cols-4 lg:grid-cols-5 grid-cols-1  justify-center '>
        <img
          src={code}
          alt='technology'
          className='md:col-span-2 lg:col-span-2 w-[435px] md:w-11/12 lg:w-full lg:h-[491px] h-[187px]  object-cover lg:mx-0 mx-auto mb-6 rounded-2xl'
        />
        <div className='md:col-span-3 lg:col-span-3 flex flex-col justify-around ml-6'>
          <h5 className='text-md lg:text-lg font-normal pb-2 text-mainText'>
            When working on the project we focused on using the most recent
            technology pieces.
          </h5>
          <p className='text-subText text-sm font-normal lg:text-mainText'>
            We wanted it to be error-free and we focused on bringing to the
            platform as many automated tests as possible. The Dcclick technology
            stack is:
          </p>
          <ul className='list-disc grid lg:grid-cols-3 pl-8 pt-4 gap-2 lg:text-mainText lg:text-smm font-normal'>
            <li>React</li>
            <li>TypeScript</li>
            <li>TailwindCss</li>
            <li>Node.js</li>
            <li>GraphQL</li>
            <li>Scss</li>
          </ul>
          <p className='text-mainText font-normal text-sm'>
            Conversation is one of our most important values and a habit we
            support at every stage of our development process. Conversation is
            executed through digital tooling so the context is never lost. We
            use Discord for chat, Devops for planning, and Github for code
            reviews and discussion.
          </p>
        </div>
      </section>
    </div>
  );
};

export default CaseStudies;
