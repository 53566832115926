export const cardsArray = [
  {
    id: 1,
    icon: "product-launch",
    title: "Startup building MVP",
    description:
      "Building an MVP (Minimum Viable Product) is a crucial step for your startup as it helps you test your product in the market, validate your assumptions, and gather feedback to improve the final product.",
  },
  {
    id: 2,
    icon: "agile",
    title: "Agile product scaling team",
    description:
      "An Agile product scaling team can help your product by delivering working software faster, improving customer satisfaction through collaboration and feedback, and promoting better communication and collaboration within your team.",
  },
  {
    id: 3,
    icon: "project-status",
    title: "Corporate digital transformation",
    description:
      "Corporate digital transformation can improve product design through the use of advanced technology, enhance manufacturing processes through automation and machine learning, and increase customer engagement through digital channels.",
  },
];
