import { ButtonHTMLAttributes, ReactNode, FC } from "react";
import "./button.styles.scss";
import RightArrow from "../right-arrow/right-arrow.component";

type Props = { children: ReactNode } & ButtonHTMLAttributes<HTMLButtonElement>;

const Button: FC<Props> = ({ children, ...buttonProps }) => {
  return (
    <button className="button" {...buttonProps}>
      {children}
      <span className="button-arrow">
        <RightArrow color="white" />
      </span>
    </button>
  );
};

export default Button;
