import { FC } from "react";
import Button from "../button/button.component";
import "./card.styles.scss";
import Icon from "../icon/icon";

type Props = {
  cardTitle: string;
  cardDescription: string;
  iconFileName?: string;
  showButton?: boolean;
};

const Card: FC<Props> = ({
  cardDescription,
  cardTitle,
  iconFileName = "rocket",
  showButton,
}) => {
  return (
    <div className="card">
      <Icon name={iconFileName} />

      <div className="card-title">{cardTitle}</div>
      <div className="card-description">{cardDescription}</div>
      {showButton ? <Button>Explore</Button> : null}
    </div>
  );
};

export default Card;
