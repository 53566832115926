import { useRef, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/button.component";
import CheckOutServices from "../../components/checkout-services-card/checkout-services-card.component";
import ClientsSection from "../../components/clients-section/clients-section.component";
import ContactSection from "../../components/contact-section/contact-section.component";
import IdentityCard from "../../components/identity-card/identity-card.component";
import ProductHelpSection from "../../components/product-help-section/product-help-section.component";
import ServicesCard from "../../components/services-card/services-card.component";
import TechnologiesSection from "../../components/technologies-section/technologies-section.component";
import TopSectionLayout from "../../components/top-section-layout/top-section-layout.component";

import "./home.styles.scss";

const servicesArray = [
  {
    id: 1,
    title: "Mobile Development",
    description: "Check out how DClick can help you  with  mobile development.",
    iconFileName: "app development service",
  },
  {
    id: 2,
    title: "Product Management",
    description:
      "Check out how DClick can play a crucial role in assisting with product management.",
    iconFileName: "business planning",
  },
  {
    id: 3,
    title: "Web Development",
    description: "Check out how DClick can help you with web development.",
    iconFileName: "screen",
  },
];

const Home = () => {
  const headerTitleContainer = useRef<HTMLDivElement>(null);
  const headerDotsContainer = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const onDotClickHandler = (
    titleClassName: string,
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const refArray = [
      { ref: headerTitleContainer, className: titleClassName, opacity: "0" },
      {
        ref: headerDotsContainer,
        className: e.currentTarget.classList[1],
        opacity: "0.5",
      },
    ];
    refArray.forEach(({ ref, className, opacity }) => {
      ref.current?.childNodes.forEach((child) => {
        if (
          child instanceof HTMLHeadingElement ||
          child instanceof HTMLDivElement
        ) {
          child.style.animation = "none";
          child.style.opacity = opacity;
        }
      });
      const childElement = ref.current?.getElementsByClassName(className);
      if (childElement) {
        if (
          childElement[0] instanceof HTMLHeadingElement ||
          childElement[0] instanceof HTMLDivElement
        ) {
          childElement[0].style.animation = "none";
          childElement[0].style.opacity = "1";
        }
      }
    });
  };

  return (
    <div className="home">
      <TopSectionLayout>
        <div className="home-header-arrow">
          <div className="arrow-line"></div>
          <div className="mouse"></div>
          <div className="scroll"></div>
        </div>
        <div className="home-header">
          <div
            className="home-header-title-container"
            ref={headerTitleContainer}
          >
            <h2 className="home-header-title t-1">
              Innovating for success - our software solutions drive results.
            </h2>
            <h2 className="home-header-title t-2">
              Innovative software solutions tailored to meet your unique
              business needs.
            </h2>
            <h2 className="home-header-title t-3">
              Revolutionizing industries with innovative software solutions.
            </h2>
            <h2 className="home-header-title t-4">
              Accelerate your business growth with our industry-leading software
              solutions.
            </h2>
          </div>

          <div className="home-header-button">
            <Button onClick={() => navigate("about-us")}>
              <span className="button-text s-1">Explore</span>
              <span className="button-text s-2">Learn More</span>
            </Button>
          </div>
        </div>
        <div className="home-header-dots" ref={headerDotsContainer}>
          <div
            className="dot dot-1"
            onClick={(e) => onDotClickHandler("t-1", e)}
          ></div>
          <div
            className="dot dot-2"
            onClick={(e) => onDotClickHandler("t-2", e)}
          ></div>
          <div
            className="dot dot-3"
            onClick={(e) => onDotClickHandler("t-3", e)}
          ></div>
          <div
            className="dot dot-4"
            onClick={(e) => onDotClickHandler("t-4", e)}
          ></div>
        </div>
      </TopSectionLayout>
      <div className="home-body">
        <div className="home-body-card">
          <IdentityCard />
        </div>
        <div className="home-services">
          {servicesArray.map(({ title, description, id, iconFileName }) => (
            <ServicesCard
              key={id}
              cardTitle={title}
              cartDescription={description}
              iconFileName={iconFileName}
              showLink
            />
          ))}
          <CheckOutServices />
        </div>
        <ProductHelpSection />
        <TechnologiesSection />
        <ClientsSection />
        <ContactSection />
      </div>
    </div>
  );
};

export default Home;
