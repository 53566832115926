import TopSectionLayout from "../../components/top-section-layout/top-section-layout.component";
import servicesImage from "../../assets/services-image.png";
import mobileServicesImage from "../../assets/mobile-services-image.png";
import ServicesCard from "../../components/services-card/services-card.component";
import { cardsArray } from "./data";
import "./services.styles.scss";

const ServicesPage = () => {
  return (
    <div className="services">
      <TopSectionLayout
        image={window.innerWidth < 1250 ? mobileServicesImage : servicesImage}
      >
        <div className="services-header">
          Take a look at how we can help your product
          <div className="services-header-body">
            <p>
              We develop your ideas by turning them into user-centered products
              ready for market validation.
            </p>
          </div>
        </div>
      </TopSectionLayout>
      <div className="services-body">
        <div className="services-body-container">
          {cardsArray.map(({ description, title }) => (
            <ServicesCard
              key={title}
              cartDescription={description}
              cardTitle={title}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default ServicesPage;
