import { Link } from "react-router-dom";
import RightArrow from "../right-arrow/right-arrow.component";
import "./checkout-services-card.styles.scss";

const CheckOutServices = () => {
  return (
    <div className="checkout-services-card">
      <h2 className="checkout-card-title">Check-out our services </h2>

      <div className="checkout-services-card-link">
        <Link to="services">
          Check out <RightArrow color="white" />
        </Link>
      </div>
    </div>
  );
};

export default CheckOutServices;
