import { Routes, Route } from "react-router-dom";
import "./App.css";
import Navigation from "./routes/navigation/navigation.component";
import Home from "./routes/home/home.component";
import ServicesPage from "./routes/services/services.component";
import AboutUs from "./routes/about-us/about-us.component";
import PrivacyPolicy from "./routes/privacy-policy/privacy-policy.component";
import CaseStudies from "./routes/CaseStudies/CaseStudies";
import DeskBooking from "./routes/CaseStudies/DeskBooking/DeskBook";
import DownloadApp from "./routes/downlaod-app/downlaod-app.component";
import MeetingRoomBooking from "./routes/CaseStudies/MeetingRoomBooking/MeetingRoomBooking";
import DeskScreens from "./routes/CaseStudies/DeskScreens/DeskScreens";
import Consulting from "./routes/consultation/Consulting";

function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigation />}>
        <Route index element={<Home />} />
        <Route path='services' element={<ServicesPage />} />
        <Route path='about-us' element={<AboutUs />} />
        <Route path='privacy-policy' element={<PrivacyPolicy />} />
        <Route path='case-studies' element={<CaseStudies />} />
        <Route path='case-studies/desk-booking' element={<DeskBooking />} />
        <Route path='case-studies/desk-screens' element={<DeskScreens />} />
        <Route path='consultation' element={<Consulting />} />
        <Route
          path='case-studies/meeting-room-booking'
          element={<MeetingRoomBooking />}
        />
      </Route>
      <Route path='download-app' element={<DownloadApp />} />
    </Routes>
  );
}

export default App;
