import { Link } from "react-router-dom";
import logo from "../../assets/dclick-logo.png";
import "./footer.styles.scss";

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-container'>
        <div className='footer-header'>
          <img src={logo} alt='dClick logo' />
          <h2>We build Dclick from scratch.</h2>
        </div>
        <div className='footer-body'>
          <div className='footer-links-container'>
            Company
            <span className='footer-link'>
              <Link to='about-us'>About us</Link>
            </span>
            <span className='footer-link'>
              <Link to='services'>Services</Link>
            </span>
            <span className='footer-link'>
              <a href='#contact'>Contact us</a>
            </span>
            <span className='footer-link'>
              <Link to='privacy-policy'>Privacy Policy</Link>
            </span>
          </div>
          <div className='footer-links-container'>
            Services
            <span className='footer-link'>
              <Link to='services'>Mobile Development</Link>
            </span>
            <span className='footer-link'>
              <Link to='services'>Web Development</Link>
            </span>
            <span className='footer-link'>
              <Link to='services'>Cloud Development and Migration</Link>
            </span>
            <span className='footer-link'>
              <Link to='services'>Product Discovery Process</Link>
            </span>
          </div>
        </div>
      </div>
      {/* © DClick. All rights reserved. */}
    </footer>
  );
};

export default Footer;
