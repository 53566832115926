import jeelWebsite from "../../assets/Jeel-website.png";
import jeelColored from "../../assets/Jeel logo colored.png";
import Button from "../button/button.component";
import FadeIn from "../fade-in/fade-in.component";
import "./clients-section.styles.scss";

const ClientsSection = () => {
  return (
    <div className="clients-section">
      <div className="clients-section-container">
        <div className="clients-section-title">
          <p>We're proud to have worked with amazing clients</p>
          <h2>Who we’ve helped?</h2>
        </div>
        <div className="clients-section-images">
          <FadeIn>
            <div className="client ul"></div>
          </FadeIn>
          <FadeIn>
            <div className="client jeel"></div>
          </FadeIn>
          <FadeIn>
            <div className="client rtgs"></div>
          </FadeIn>
          <FadeIn>
            <div className="client british-gas"></div>
          </FadeIn>
        </div>
        <div className="clients-section-main-client">
          <div className="main-client-description">
            <img src={jeelColored} alt="main website logo" />
            <p>
              Worem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim,{" "}
            </p>
            <Button>Explore</Button>
          </div>
          <div className="main-client-image">
            <img src={jeelWebsite} alt="main website" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsSection;
