import TopSectionLayout from "../../components/top-section-layout/top-section-layout.component";
import "./privacy-policy.styles.scss";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <TopSectionLayout>
        <h2 className="privacy-policy-title">Privacy Policy</h2>
      </TopSectionLayout>
      <div className="privacy-policy-body">
        <h1>Privacy Policy</h1>
        <p>Your data is very important to DClick</p>

        <p>
          DClick(&ldquo;we,&rdquo; or &ldquo;us&rdquo;) is committed to
          protecting the information that you share with us online, and
          explaining how we collect, process, and share that information. We
          provide you with the notice below in English only to avoid any
          misinterpretations caused by a language translation. If necessary,
          please seek the proper advice, or avoid use of this website.
        </p>

        <p>Summary of Key Issues</p>

        <p>
          Collection We collect Personal Information about you that you provide
          to us or that we obtain while you are using the DClickwebsite site or
          Services supplied.
          <br />
          Protection We employ reasonable and current technical, administrative,
          and physical safeguards that are designed to prevent unauthorized
          access, maintain data accuracy, and designed to ensure correct use of
          Personal Information.
          <br />
          Use We use Personal Information to provide you with services from
          dclickltd.com and our Sites, to build features that will make the
          services available on the Site easier to use, to contact you about
          these services, and to send advertisements, marketing material or
          other offers that we think might be of interest to you.
          <br />
          Marketing and Choices You have control on how we use Personal
          Information for marketing. You can exercise your control in any
          marketing communication.
          <br />
          Sharing We do not share your data with any other 3rd parties.
          <br />
          Cookies Our Sites and dclickltd.com uses cookies and other similar
          technologies.
          <br />
          Access and Data Subject Rights We understand that you may want to
          change, access, or delete your Personal Information. You may do so by
          accessing your profile or by contacting us. Via the Website contact us
          forms or by email info@dclickltd.com.
          <br />
          Please be aware that if you are a Company User requesting changes to
          the information / data that is stored by DClickon behalf of our
          customer your request will be forwarded to the relevant Company.
        </p>

        <p>
          Children Persons under the age of 16 are not eligible to use any
          services on our Sites.
          <br />
          Changes We will provide notice of any material changes to this Privacy
          Policy by posting a prominent notice on the DClickwebsite or through
          the Services Channel you are engaged with.
          <br />
          Contact Us Should you have any questions, concerns or comments, please
          contact us. Website contact us forms or by email info@dclickltd.com.
          <br />
        </p>

        <p>Scope and Information that this Privacy Policy covers</p>

        <p>
          This Privacy Policy applies to information collected on our websites,
          mobile applications, cloud- based services, or controlled widgets
          embedded in communication platforms with a link to this Privacy Policy
          (collectively, the &ldquo;Site&rdquo;) and all channels for collecting
          data for Sales and Marketing purposes. It covers how we collect, use,
          share, and otherwise process Personal Information as of the date that
          this Privacy Policy is posted. It does not apply to any other
          information collected by us through any other means. By
          &ldquo;Personal Information,&rdquo; we mean information that
          identifies or can identify you personally as defined by ICO - Home |
          ICO.
        </p>

        <p>
          Personal Information we collect about you on our website
          www.dclickltd.com, via marketing activities and / or via software
          services provided by DClick
        </p>

        <p>
          We collect your Personal Information on the Site to enhance the
          services we offer you, maintain and improve the Site, protect the
          security of you and our Sites and DClickLtd.com, comply with legal
          obligations, and inform you about other services and products that may
          be available through us, our affiliated companies, or through our
          marketing alliances. We collect Personal Information about you when
          you interact with or utilise services offered on this Site, such as:
        </p>

        <p>
          Creating a profile with us via the contact forms
          <br />
          Name
          <br />
          Postal or billing address
          <br />
          E-mail address
          <br />
          Telephone or mobile number
          <br />
          Consenting to marketing communications
          <br />
          Applying for a job
          <br />
          Submitting comments, reviews, or other user-generated content
          <br />
          Connecting or interacting with us through social networks (e.g.
          LinkedIn Facebook, Instagram, Twitter)
          <br />
          Requesting customer or technical
          <br />
          Further Personal Information that can be collected via the use of
          DClicksoftware includes:
        </p>

        <p>
          Name
          <br />
          Postal or billing address
          <br />
          E-mail address
          <br />
          Telephone or mobile number
          <br />
          Payment card information
          <br />
          Government identification (e.g., passport identification to verify
          traveller identities)
          <br />
          Location via IP address
          <br />
          Device being used (for our mobile site)
          <br />
          Cookies and similar technologies
          <br />
          Other relevant
          <br />
          Even if you do not provide us with any Personal Information, we
          collect certain non-personal information about how you use our Site.
          This information cannot identify you and is used for statistical
          purposes only.
        </p>

        <p>Use and sharing of personal information</p>

        <p>
          We only process and share your Personal Information, including sharing
          with service providers or other external entities, to the extent
          reasonably necessary to fulfil your requests and meet our legitimate
          business and legal objectives. When we disclose Personal Information
          to external entities to perform services for us, we establish by
          contract that they may access your Personal Information only for the
          purposes of performing those services. We may also use what we know
          about you to offer you other products and services. We may combine
          your online information with information collected from offline or
          online sources, or information we already have. We may also use and
          disclose certain Personal Information to our affiliated companies and
          others for any purpose allowed by law.
        </p>

        <p>
          We generally process and may disclose your Personal Information to
          others to:
        </p>

        <p>
          Provide you with products and services, including authenticating
          authorised users, and marketing our products and services
          <br />
          Perform certain services or other transactions initiated by you
          <br />
          Respond to your questions, complaints, or reviews of our product or
          services
          <br />
          Send you communications about online transactions, product
          information, ads and promotions, electronic newsletters, or other
          notices you requested or offers tailored to you
          <br />
          Enable our advertisers, where appropriate, to provide you with more
          personalised content, and track the effectiveness of certain
          advertising campaigns
          <br />
          Comply with applicable law, obey judicial orders, co-operate with law
          enforcement authorities, or prevent any suspected illegal activities
          <br />
          Help us run our business; or
          <br />
          For any other everyday business purposes, such as product development
          and Site
          <br />
          Processing basis and consequences
        </p>

        <p>
          Certain jurisdictions, including those in Europe, require the
          identification of the legal grounds for the collection, use, sharing,
          and other processing of Personal Information. We rely on the following
          legal grounds for its collection, use, sharing, and other processing
          of Personal Information as described in this Privacy Policy:
        </p>

        <p>
          Necessary to provide information or otherwise carry out the
          performance of a contract with you as an individual or as an employee
          of a customer subscribing company to DClicksoftware services.
          <br />
          Our legitimate interests, including:
          <br />
          Customer relationship management and other forms of marketing and
          <br />
          Fraud prevention, misuse of company IT systems, or money
          <br />
          Physical, IT, and network perimeter
          <br />
          Internal investigations; and
          <br />
          Intended mergers and
          <br />
          Compliance with legal obligations and/or defence against legal claims,
          including those in the area of labour law, social security, and data
          protection, tax, and corporate compliance laws.
          <br />
          Protection of the vital interests of any
          <br />
          Performance of a task carried out in the public interest or in the
          exercise of official authority vested in us; and
          <br />
          Consent, as permitted by applicable
          <br />
          Providing us with Personal Information is voluntary, but your refusal
          to provide Personal Information for the above-mentioned purposes may
          affect our ability to fulfil our contractual obligations.
        </p>

        <p>Your rights concerning your Personal Information</p>

        <p>
          You have the following choices regarding our use and disclosure of
          your Personal Information:
        </p>

        <p>
          Marketing Communications. If you no longer wish to receive any
          marketing communications, remain on a mailing list to which you
          previously subscribed or receive any other communication from us,
          please follow the unsubscribe link in the relevant communication or
          send an email to info@dclickltd.com. Even after you opt-out or update
          your marketing preferences, we may still contact you for transactional
          or informational purposes. These include, for example, customer
          service issues, surveys, or any questions regarding a specific
          reservation.
          <br />
          Cookies and similar technologies. Please review your browser or
          computer settings for certain cookies and see below to exercise
          certain choices regarding cookies.
          <br />
          You are not required to provide all Personal Information identified in
          this Privacy Policy to use our Site, but certain aspects of our Site
          may not be available to you. However, you can use our services without
          consenting to non-essential cookies or direct marketing emails; the
          only consequence is that we will communicate less to you regarding our
          services, we may not be able to provide the support you seek, and you
          may miss out on attractive promotions and news about services.
        </p>

        <p>
          Europe and certain other non-US jurisdictions maintain local data
          protection regulations that confer certain data protection rights on
          individuals. If applicable, and you wish to exercise any of these
          rights, please contact us as set out below.
        </p>

        <p>
          Right of access: You have the right to obtain from us confirmation as
          to whether or not Personal Information concerning you is processed,
          and, to request access to the Personal The access information
          includes, among other things, the purposes of the processing, the
          categories of Personal Information concerned, and the recipients or
          categories of recipient to whom the Personal Information have been or
          will be disclosed. This is not, however, an absolute right, and the
          interests of other individuals may restrict your right of access. You
          may have the right to obtain a copy of your Personal Information
          undergoing processing.
          <br />
          Right to rectification: You have the right to obtain from us the
          rectification of inaccurate Personal Information about you. Depending
          on the purposes of the processing, you have the right to have
          incomplete Personal Information completed, including by means of
          providing a supplementary
          <br />
          Right to erasure (right to be forgotten): Under certain circumstances,
          you have the right to obtain from us the erasure of Personal
          Information concerning you, and we may be obligated to erase that
          Personal
          <br />
          Right to restriction of processing: Under certain circumstances, you
          have the right to obtain from us restriction of processing your
          Personal Information. In that case, your data will be marked and may
          only be processed by us for certain limited
          <br />
          Right to data portability: Under certain circumstances, you have the
          right to receive the Personal Information about you, which you have
          provided to us, in a structured, commonly used and machine-readable
          format, and you have the right to transmit that data to another entity
          without hindrance from
          <br />
          Right to object: Under certain circumstances, and at any time, you
          have the right to object, on grounds relating to your particular
          situation, to the processing of your Personal Information by us, and
          we can be required to no longer process your Personal
          <br />
          Where permitted by applicable law, you also have the right to lodge a
          complaint with a competent data protection supervisory authority.
        </p>

        <p>Security of your information</p>

        <p>
          We maintain reasonable technical, electronic, and organisational
          security procedures to maintain security of Personal Information and
          safeguard Personal Information against unauthorised or unlawful
          processing and/or against accidental or unlawful loss, alteration,
          disclosure or access. Our security procedures include contractual
          terms with any contractors, agents or data processors that require
          such entities to protect the security and confidentiality of Personal
          Information in accordance with our standards. While we strive to
          protect your Personal Information, we cannot ensure the security of
          the information you transmit. It is your responsibility to safeguard
          any email or password that you have created or used in connection with
          any service or site and to notify us if you ever suspect that the
          security and confidentiality of such email or password has been
          compromised in any way.
        </p>

        <p>Retention and storage of your information</p>

        <p>
          We will maintain Personal Information for as long as we are required
          to do so by applicable law(s), or for as long as necessary for the
          purpose(s) described above for which it is processed. We will delete
          Personal Information when it is no longer needed and/or take steps to
          properly anonymise it so that you can no longer be identified from it
          (unless we need to keep your information to comply with legal or
          regulatory obligations to which we are subject) and, in any case, upon
          expiration of the maximum storage term set forth by applicable law.
        </p>

        <p>Children</p>

        <p>
          We do not knowingly collect Personal Information from children under
          the age of 16, and users under the age of 16 should not submit any
          Personal Information to us. If we have actual knowledge that Personal
          Information about a child under 16 years old has been collected, then
          we will take the appropriate steps to delete this Personal
          Information.
        </p>

        <p>Changes to our Privacy Policy</p>

        <p>
          We may change this policy from time to time. If we make any material
          change in how we collect, use, disclose, or otherwise process Personal
          Information, we will prominently post notice of the change on our site
          for at least thirty (30) days before putting the change into effect.
          Where required to do so by law, we may seek your prior consent to any
          material changes we make to this Privacy Policy. Otherwise, your
          continued use of our site after we make changes to this policy is
          deemed acceptance of those changes. If any proposed change is
          unacceptable to you, you may request that we remove your Personal
          Information (and/or that of other individuals for whom you made your
          travel reservations) from our records.
        </p>

        <p>Contact us</p>

        <p>
          If you have any questions or comments regarding this Privacy Policy or
          our privacy practices in general, please contact us as detailed below.
          You may also have a right to lodge a complaint with a supervisory
          authority.
        </p>

        <p>
          Email: info@dclickltd.com if the request is a direct GDPR question.
        </p>

        <p>Mailing Address:</p>

        <p>
          DClickHouse, Shrewsbury Business Park, Shrewsbury,
          <br />
          SY2 6LG,
          <br />
          United Kingdom.
        </p>

        <p>
          Cookie Policy
          <br />
          Background
        </p>

        <p>
          This Direct Booking Manager (DBM) and DClickLtd.com (&ldquo;Our
          Sites&rdquo;) uses Cookies and similar technologies in order to
          distinguish you from other users. By using Cookies, we are able to
          provide you with a better experience and to improve our DBM by better
          understanding how you use it. Please read this Cookie Policy carefully
          and ensure that you understand it. Your acceptance of our Cookie
          Policy is deemed to occur if you continue using our DBM. If you do not
          agree to our Cookie Policy, please stop using our DBM immediately.
        </p>

        <p>
          1. Definitions and interpretation
          <br />
          In this Cookie Policy, unless the context otherwise requires, the
          following expressions have the following meanings:
        </p>

        <p>
          &ldquo;Cookie&rdquo; means a small file placed on your computer or
          device by our Sites when you visit certain parts of our Sites and/or
          when you use certain features of our Sites;
          <br />
          &ldquo;Cookie Law&rdquo; means the relevant parts of the Privacy and
          Electronic Communications (EC Directive) Regulations 2003 and of EU
          Regulation 2016/679 General Data Protection Regulation
          (&ldquo;GDPR&rdquo;);
          <br />
          &ldquo;Personal Data&rdquo; means any and all data that relates to an
          identifiable person who can be directly or indirectly identified from
          that data, as defined by EU Regulation 2016/679 General Data
          Protection Regulation (&ldquo;GDPR&rdquo;); and
          <br />
          &ldquo;We/Us/Our&rdquo; means DClickLimited, company no.02661520,
          whose registered office is: DClickHouse, Shrewsbury Business Park,
          Shrewsbury, SY2 6LG, United Kingdom (&ldquo;DClick&rdquo;).
          <br />
          2. Information About Us
        </p>

        <p>
          Our Sites owned and operated by us, DClick. If you would like to know
          more about how we use Cookies, please contact us at info@dclickltd.com
        </p>

        <p>
          3. How do we use Cookies?
          <br />
          Our Sites may place and access certain first party Cookies on your
          computer or device. First party Cookies are those placed directly by
          us and are used only by us. We use Cookies to facilitate and improve
          your experience of our Sites and to provide and improve our products
          and services. We have carefully chosen these Cookies and have taken
          steps to ensure that your privacy and personal data is protected and
          respected at all times.
        </p>

        <p>
          By using our Sites, you may also receive certain third-party Cookies
          on your computer or device. Third party Cookies are those placed by
          websites, services, and/or parties other than us. Third party Cookies
          are used on our Sites for website usage insights and advertising
          purposes. By collecting this data, we can continually improve the
          website experience. These Cookies are small text files which are
          stored on your computer by your web browser. They are not computer
          programs, and they can&rsquo;t read any other information saved on
          your hard drive. They can&rsquo;t spread viruses or obtain sensitive
          user information such as email addresses and credit card numbers.
        </p>

        <p>
          All Cookies used by and on our Sites are used in accordance with
          current Cookie Law. We may use some or all of the following types of
          Cookie:
        </p>

        <p>
          &middot; Strictly necessary Cookies
          <br />
          A Cookie falls into this category if it is essential to the operation
          of our Sites, supporting functions such as logging in, your shopping
          basket, and payment transactions.
          <br />
          &middot; Analytics Cookies
          <br />
          It is important for us to understand how you use our Sites, for
          example, how efficiently you are able to navigate around it, and what
          features you use. Analytics Cookies enable us to gather this
          information, helping us to improve our Sites and your experience of
          it.
          <br />
          &middot; Functionality Cookies
          <br />
          Functionality Cookies enable us to provide additional functions to you
          on our Sites such as personalisation and remembering your saved
          preferences. Some functionality Cookies may also be strictly necessary
          Cookies, but not all necessarily fall into that category.
          <br />
          &middot; Targeting Cookies
          <br />
          It is important for us to know when and how often you visit our Sites,
          and which parts of it you have used (including which pages you have
          visited, and which links you have visited). As with Analytical
          Cookies, this information helps us to better understand you and, in
          turn, to make our Sites and advertising more relevant to your
          interests. Some information gathered by targeting Cookies may also be
          shared with third parties.
          <br />
          &middot; Third Party Cookies
          <br />
          Third party Cookies are not placed by us; instead, they are placed by
          third parties that provide services to us and/or to you. Third party
          Cookies may be used by advertising services to serve up tailored
          advertising to you on our Sites, or by third parties providing
          analytical services to us (these Cookies will work in the same way as
          analytics Cookies described above).
          <br />
          &middot; Persistent Cookies
          <br />
          Any of the above types of Cookie may be a persistent Cookie.
          Persistent Cookies are those which remain on your computer or device
          for a pre-determined period and are activated each time you visit our
          Sites.
          <br />
          &middot; Session Cookies
          <br />
          Any of the above types of Cookie may be a session Cookie. Session
          Cookies are temporary and only remain on your computer or device from
          the point at which you visit our Sites until you close your browser.
          Session Cookies are deleted when you close your browser.
        </p>

        <p>
          Cookies on our Sites are not permanent and will expire as indicated in
          the table below.
        </p>

        <p>
          For more details of the personal data that we collect and use, the
          measures we have in place to protect personal data, your legal rights,
          and our legal obligations, please refer to our Privacy Policy.
        </p>

        <p>
          For more specific details of the Cookies that we use, please refer to
          the table below.
          <br />
          4. What Cookies does our Sites use?
          <br />
          The following third-party Cookies may be placed on your computer or
          device:
        </p>

        <p>
          Name of Cookie Purpose &amp; Type Provider
          <br />
          <br />
          DoubleClick
        </p>

        <p>
          These Cookies identify user sessions, passing information back to
          Google Analytics. With this information, we can improve the website
          experience.{" "}
          <a href="https://www.doubleclickbygoogle.com/">
            https://www.doubleclickbygoogle.com/
          </a>
          <br />
          <br />
          Google Tag Manager
        </p>

        <p>
          These Cookies identify user sessions, passing information back to
          Google Analytics. With this information, we can improve the website
          experience. Google.com
          <br />
          <br />
          Facebook
        </p>

        <p>
          This Cookie identifies user sessions, passing information back to
          Facebook&rsquo;s advertising platform. This allows us to communicate
          the latest offers and events via Facebook and Instagram ads.
          Facebook.com
          <br />
          Fullstory To track and improve the use of the website:{" "}
          <a href="https://www.fullstory.com/">https://www.fullstory.com/</a>
          <br />
          Omniconvert Session cookies to provide specific web content to
          relevant users:{" "}
          <a href="https://www.omniconvert.com/">
            https://www.omniconvert.com/
          </a>
          <br />
          Response Tap Adds unique phone numbers so that calls derived from the
          website can be tracked{" "}
          <a href="https://www.responsetap.com/">
            https://www.responsetap.com/
          </a>
          <br />
          Our Sites uses analytics services provided by &lt;&lt;Insert Name of
          Analytics Service Provider(s)&gt;&gt;.Google.com, Facebook.com and
          Responsetap.com. Website analytics refers to a set of tools used to
          collect and analyse anonymous usage information, enabling us to better
          understand how our Sites is used. This, in turn, enables us to improve
          our Sites and the products and services offered through it. You do not
          have to allow us to use these Cookies, however whilst our use of them
          does not pose any risk to your privacy or your safe use of our Sites,
          it does enable us to continually improve our Sites, making it a better
          and more useful experience for you.
        </p>

        <p>
          The analytics service used by our Sites use analytics Cookies to
          gather the required information. The analytics services used by our
          Sites uses the following analytics Cookies:
        </p>

        <p>
          Name of Cookie Purpose &amp; Type Provider
          <br />
          Universal Analytics These Cookies identify user sessions, passing
          information back to Google Analytics. With this information, we can
          improve the website experience.{" "}
          <a href="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </a>
          <br />
          Facebook Pixel This Cookie identifies user sessions, passing
          information back to Facebook&rsquo;s advertising platform. This allows
          us to communicate the latest offers and events via Facebook and
          Instagram ads.{" "}
          <a href="https://www.facebook.com/">https://www.facebook.com/</a>
          <br />
          Response Tap This Cookie identifies user sessions, passing information
          back to Response Tap&rsquo;s reporting platform. This allows us to see
          which page a user made a phone call from.{" "}
          <a href="https://www.responsetap.com/">
            https://www.responsetap.com/
          </a>
        </p>

        <p>5. DBM</p>

        <p>What Cookies do we use in our Direct Booking Manager?</p>

        <p>We use the following Cookies:</p>

        <p>
          Strictly necessary Cookies - We only need JSESSIONID in the OBM
          <br />
          Analytical/performance Cookies - They allow us to recognise and count
          the number of visitors and to see how visitors move around our website
          when they are using it. This helps us to improve the way our website
          works, for example, by ensuring that users are finding what they are
          looking for easily. These cookies are only used by DClickengineers and
          are only for internal use for tracking and reporting
          Analytical/performance and are ai_session and ai_user. They cannot be
          shared or accessed outside of DClick
          <br />
          Functionality Cookies - Not used by DClick
          <br />
          Targeting Cookies - Not used by DClick
          <br />
          Where can you find more information?
        </p>

        <p>
          You can find more information about the individual Cookies we use and
          the purposes for which we use them in the table below:
        </p>

        <p>
          Cookie Name Purpose More information
          <br />
          Java session JSESSIONID This Cookie will track the users purchase/s
          through the buying journey on the site. From initial product selection
          to final check out.
          <br />
        </p>

        <p>This Cookie is essential for our site to work.</p>

        <p>
          This Cookie contains no personal identifiable information it just
          contains a tag code to identify the user throughout the session. For
          example, as you add items to your shopping bag and move to the next
          screen it remembers what is in your bag.
          <br />
          Application Insights ai_session This just tracks an individual
          session. The Cookie will track the time a visitor is on a page and
          when they move to another page will identify them as the same user.
          <br />
          Application Insights ai_user This is a prevention mechanism Cookie.
          This Cookie monitors how many users are on the page or accessing the
          page at any one time. If there was a flood of activity, we would
          investigate as it may flag a blunt force attack.
          <br />
          6. Control
          <br />
          In addition to the controls that we provide, you can choose to enable
          or disable Cookies in your internet browser. Most internet browsers
          also enable you to choose whether you wish to disable all Cookies or
          only third-party Cookies. By default, most internet browsers accept
          Cookies but this can be changed.
        </p>

        <p>
          The links below provide instructions on how to control Cookies in all
          mainstream browsers: Google Chrome:{" "}
          <a href="https://support.google.com/chrome/answer/95647?hl=en-GB">
            https://support.google.com/chrome/answer/95647?hl=en-GB
          </a>
        </p>

        <p>
          Microsoft Internet Explorer: Delete and manage cookies (microsoft.com)
        </p>

        <p>
          Safari (macOS):{" "}
          <a href="https://support.apple.com/kb/PH21411?viewlocale=en_GB&locale=en_GB">
            https://support.apple.com/kb/PH21411?viewlocale=en_GB&locale=en_GB
          </a>
          Safari (iOS):{" "}
          <a href="https://support.apple.com/en-gb/HT201265">
            https://support.apple.com/en-gb/HT201265
          </a>
        </p>

        <p>
          Mozilla Firefox:{" "}
          <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences">
            https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences
          </a>
        </p>

        <p>
          Android:{" "}
          <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en">
            https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en
          </a>
        </p>

        <p>
          7. Changes to this Cookie Policy
          <br />
          We may alter this Cookie Policy at any time. Any such changes will
          become binding on you on your first use of our Sites after the changes
          have been made. You are therefore advised to check this page
          regularly.
        </p>

        <p>
          In the event of any conflict between the current version of this
          Cookie Policy and any previous versions, the current provisions in
          effect shall prevail unless it is expressly stated otherwise.Privacy
          Policy
          <br />
          Your data is very important to DClick
        </p>

        <p>
          DClick(&ldquo;we,&rdquo; or &ldquo;us&rdquo;) is committed to
          protecting the information that you share with us online, and
          explaining how we collect, process, and share that information. We
          provide you with the notice below in English only to avoid any
          misinterpretations caused by a language translation. If necessary,
          please seek the proper advice, or avoid use of this website.
        </p>

        <p>Summary of Key Issues</p>

        <p>
          Collection We collect Personal Information about you that you provide
          to us or that we obtain while you are using the DClickwebsite site or
          Services supplied.
          <br />
          Protection We employ reasonable and current technical, administrative,
          and physical safeguards that are designed to prevent unauthorized
          access, maintain data accuracy, and designed to ensure correct use of
          Personal Information.
          <br />
          Use We use Personal Information to provide you with services from
          dclickltd.com and our Sites, to build features that will make the
          services available on the Site easier to use, to contact you about
          these services, and to send advertisements, marketing material or
          other offers that we think might be of interest to you.
          <br />
          Marketing and Choices You have control on how we use Personal
          Information for marketing. You can exercise your control in any
          marketing communication.
          <br />
          Sharing We do not share your data with any other 3rd parties.
          <br />
          Cookies Our Sites and dclickltd.com uses cookies and other similar
          technologies.
          <br />
          Access and Data Subject Rights We understand that you may want to
          change, access, or delete your Personal Information. You may do so by
          accessing your profile or by contacting us. Via the Website contact us
          forms or by email info@dclickltd.com .<br />
          Please be aware that if you are a Company User requesting changes to
          the information / data that is stored by DClickon behalf of our
          customer your request will be forwarded to the relevant Company.
        </p>

        <p>
          Children Persons under the age of 16 are not eligible to use any
          services on our Sites.
          <br />
          Changes We will provide notice of any material changes to this Privacy
          Policy by posting a prominent notice on the DClickwebsite or through
          the Services Channel you are engaged with.
          <br />
          Contact Us Should you have any questions, concerns or comments, please
          contact us. Website contact us forms or by email info@dclickltd.com.
          <br />
        </p>

        <p>Scope and Information that this Privacy Policy covers</p>

        <p>
          This Privacy Policy applies to information collected on our websites,
          mobile applications, cloud- based services, or controlled widgets
          embedded in communication platforms with a link to this Privacy Policy
          (collectively, the &ldquo;Site&rdquo;) and all channels for collecting
          data for Sales and Marketing purposes. It covers how we collect, use,
          share, and otherwise process Personal Information as of the date that
          this Privacy Policy is posted. It does not apply to any other
          information collected by us through any other means. By
          &ldquo;Personal Information,&rdquo; we mean information that
          identifies or can identify you personally as defined by ICO - Home |
          ICO.
        </p>

        <p>
          Personal Information we collect about you on our website
          www.dclickltd.com, via marketing activities and / or via software
          services provided by DClick
        </p>

        <p>
          We collect your Personal Information on the Site to enhance the
          services we offer you, maintain and improve the Site, protect the
          security of you and our Sites and DClickLtd.com, comply with legal
          obligations, and inform you about other services and products that may
          be available through us, our affiliated companies, or through our
          marketing alliances. We collect Personal Information about you when
          you interact with or utilise services offered on this Site, such as:
        </p>

        <p>
          Creating a profile with us via the contact forms
          <br />
          Name
          <br />
          Postal or billing address
          <br />
          E-mail address
          <br />
          Telephone or mobile number
          <br />
          Consenting to marketing communications
          <br />
          Applying for a job
          <br />
          Submitting comments, reviews, or other user-generated content
          <br />
          Connecting or interacting with us through social networks (e.g.
          LinkedIn Facebook, Instagram, Twitter)
          <br />
          Requesting customer or technical
          <br />
          Further Personal Information that can be collected via the use of
          DClicksoftware includes:
        </p>

        <p>
          Name
          <br />
          Postal or billing address
          <br />
          E-mail address
          <br />
          Telephone or mobile number
          <br />
          Payment card information
          <br />
          Government identification (e.g., passport identification to verify
          traveller identities)
          <br />
          Location via IP address
          <br />
          Device being used (for our mobile site)
          <br />
          Cookies and similar technologies
          <br />
          Other relevant
          <br />
          Even if you do not provide us with any Personal Information, we
          collect certain non-personal information about how you use our Site.
          This information cannot identify you and is used for statistical
          purposes only.
        </p>

        <p>Use and sharing of personal information</p>

        <p>
          We only process and share your Personal Information, including sharing
          with service providers or other external entities, to the extent
          reasonably necessary to fulfil your requests and meet our legitimate
          business and legal objectives. When we disclose Personal Information
          to external entities to perform services for us, we establish by
          contract that they may access your Personal Information only for the
          purposes of performing those services. We may also use what we know
          about you to offer you other products and services. We may combine
          your online information with information collected from offline or
          online sources, or information we already have. We may also use and
          disclose certain Personal Information to our affiliated companies and
          others for any purpose allowed by law.
        </p>

        <p>
          We generally process and may disclose your Personal Information to
          others to:
        </p>

        <p>
          Provide you with products and services, including authenticating
          authorised users, and marketing our products and services
          <br />
          Perform certain services or other transactions initiated by you
          <br />
          Respond to your questions, complaints, or reviews of our product or
          services
          <br />
          Send you communications about online transactions, product
          information, ads and promotions, electronic newsletters, or other
          notices you requested or offers tailored to you
          <br />
          Enable our advertisers, where appropriate, to provide you with more
          personalised content, and track the effectiveness of certain
          advertising campaigns
          <br />
          Comply with applicable law, obey judicial orders, co-operate with law
          enforcement authorities, or prevent any suspected illegal activities
          <br />
          Help us run our business; or
          <br />
          For any other everyday business purposes, such as product development
          and Site
          <br />
          Processing basis and consequences
        </p>

        <p>
          Certain jurisdictions, including those in Europe, require the
          identification of the legal grounds for the collection, use, sharing,
          and other processing of Personal Information. We rely on the following
          legal grounds for its collection, use, sharing, and other processing
          of Personal Information as described in this Privacy Policy:
        </p>

        <p>
          Necessary to provide information or otherwise carry out the
          performance of a contract with you as an individual or as an employee
          of a customer subscribing company to DClicksoftware services.
          <br />
          Our legitimate interests, including:
          <br />
          Customer relationship management and other forms of marketing and
          <br />
          Fraud prevention, misuse of company IT systems, or money
          <br />
          Physical, IT, and network perimeter
          <br />
          Internal investigations; and
          <br />
          Intended mergers and
          <br />
          Compliance with legal obligations and/or defence against legal claims,
          including those in the area of labour law, social security, and data
          protection, tax, and corporate compliance laws.
          <br />
          Protection of the vital interests of any
          <br />
          Performance of a task carried out in the public interest or in the
          exercise of official authority vested in us; and
          <br />
          Consent, as permitted by applicable
          <br />
          Providing us with Personal Information is voluntary, but your refusal
          to provide Personal Information for the above-mentioned purposes may
          affect our ability to fulfil our contractual obligations.
        </p>

        <p>Your rights concerning your Personal Information</p>

        <p>
          You have the following choices regarding our use and disclosure of
          your Personal Information:
        </p>

        <p>
          Marketing Communications. If you no longer wish to receive any
          marketing communications, remain on a mailing list to which you
          previously subscribed or receive any other communication from us,
          please follow the unsubscribe link in the relevant communication or
          send an email to info@dclickltd.com. Even after you opt-out or update
          your marketing preferences, we may still contact you for transactional
          or informational purposes. These include, for example, customer
          service issues, surveys, or any questions regarding a specific
          reservation.
          <br />
          Cookies and similar technologies. Please review your browser or
          computer settings for certain cookies and see below to exercise
          certain choices regarding cookies.
          <br />
          You are not required to provide all Personal Information identified in
          this Privacy Policy to use our Site, but certain aspects of our Site
          may not be available to you. However, you can use our services without
          consenting to non-essential cookies or direct marketing emails; the
          only consequence is that we will communicate less to you regarding our
          services, we may not be able to provide the support you seek, and you
          may miss out on attractive promotions and news about services.
        </p>

        <p>
          Europe and certain other non-US jurisdictions maintain local data
          protection regulations that confer certain data protection rights on
          individuals. If applicable, and you wish to exercise any of these
          rights, please contact us as set out below.
        </p>

        <p>
          Right of access: You have the right to obtain from us confirmation as
          to whether or not Personal Information concerning you is processed,
          and, to request access to the Personal The access information
          includes, among other things, the purposes of the processing, the
          categories of Personal Information concerned, and the recipients or
          categories of recipient to whom the Personal Information have been or
          will be disclosed. This is not, however, an absolute right, and the
          interests of other individuals may restrict your right of access. You
          may have the right to obtain a copy of your Personal Information
          undergoing processing.
          <br />
          Right to rectification: You have the right to obtain from us the
          rectification of inaccurate Personal Information about you. Depending
          on the purposes of the processing, you have the right to have
          incomplete Personal Information completed, including by means of
          providing a supplementary
          <br />
          Right to erasure (right to be forgotten): Under certain circumstances,
          you have the right to obtain from us the erasure of Personal
          Information concerning you, and we may be obligated to erase that
          Personal
          <br />
          Right to restriction of processing: Under certain circumstances, you
          have the right to obtain from us restriction of processing your
          Personal Information. In that case, your data will be marked and may
          only be processed by us for certain limited
          <br />
          Right to data portability: Under certain circumstances, you have the
          right to receive the Personal Information about you, which you have
          provided to us, in a structured, commonly used and machine-readable
          format, and you have the right to transmit that data to another entity
          without hindrance from
          <br />
          Right to object: Under certain circumstances, and at any time, you
          have the right to object, on grounds relating to your particular
          situation, to the processing of your Personal Information by us, and
          we can be required to no longer process your Personal
          <br />
          Where permitted by applicable law, you also have the right to lodge a
          complaint with a competent data protection supervisory authority.
        </p>

        <p>Security of your information</p>

        <p>
          We maintain reasonable technical, electronic, and organisational
          security procedures to maintain security of Personal Information and
          safeguard Personal Information against unauthorised or unlawful
          processing and/or against accidental or unlawful loss, alteration,
          disclosure or access. Our security procedures include contractual
          terms with any contractors, agents or data processors that require
          such entities to protect the security and confidentiality of Personal
          Information in accordance with our standards. While we strive to
          protect your Personal Information, we cannot ensure the security of
          the information you transmit. It is your responsibility to safeguard
          any email or password that you have created or used in connection with
          any service or site and to notify us if you ever suspect that the
          security and confidentiality of such email or password has been
          compromised in any way.
        </p>

        <p>Retention and storage of your information</p>

        <p>
          We will maintain Personal Information for as long as we are required
          to do so by applicable law(s), or for as long as necessary for the
          purpose(s) described above for which it is processed. We will delete
          Personal Information when it is no longer needed and/or take steps to
          properly anonymise it so that you can no longer be identified from it
          (unless we need to keep your information to comply with legal or
          regulatory obligations to which we are subject) and, in any case, upon
          expiration of the maximum storage term set forth by applicable law.
        </p>

        <p>Children</p>

        <p>
          We do not knowingly collect Personal Information from children under
          the age of 16, and users under the age of 16 should not submit any
          Personal Information to us. If we have actual knowledge that Personal
          Information about a child under 16 years old has been collected, then
          we will take the appropriate steps to delete this Personal
          Information.
        </p>

        <p>Changes to our Privacy Policy</p>

        <p>
          We may change this policy from time to time. If we make any material
          change in how we collect, use, disclose, or otherwise process Personal
          Information, we will prominently post notice of the change on our site
          for at least thirty (30) days before putting the change into effect.
          Where required to do so by law, we may seek your prior consent to any
          material changes we make to this Privacy Policy. Otherwise, your
          continued use of our site after we make changes to this policy is
          deemed acceptance of those changes. If any proposed change is
          unacceptable to you, you may request that we remove your Personal
          Information (and/or that of other individuals for whom you made your
          travel reservations) from our records.
        </p>

        <p>Contact us</p>

        <p>
          If you have any questions or comments regarding this Privacy Policy or
          our privacy practices in general, please contact us as detailed below.
          You may also have a right to lodge a complaint with a supervisory
          authority.
        </p>

        <p>
          Email: info@dclickltd.com if the request is a direct GDPR question.
        </p>

        <p>Mailing Address:</p>

        <p>
          DClickHouse, Shrewsbury Business Park, Shrewsbury,
          <br />
          SY2 6LG,
          <br />
          United Kingdom.
        </p>

        <p>
          Cookie Policy
          <br />
          Background
        </p>

        <p>
          This Direct Booking Manager (DBM) and DClickLtd.com (&ldquo;Our
          Sites&rdquo;) uses Cookies and similar technologies in order to
          distinguish you from other users. By using Cookies, we are able to
          provide you with a better experience and to improve our DBM by better
          understanding how you use it. Please read this Cookie Policy carefully
          and ensure that you understand it. Your acceptance of our Cookie
          Policy is deemed to occur if you continue using our DBM. If you do not
          agree to our Cookie Policy, please stop using our DBM immediately.
        </p>

        <p>
          1. Definitions and interpretation
          <br />
          In this Cookie Policy, unless the context otherwise requires, the
          following expressions have the following meanings:
        </p>

        <p>
          &ldquo;Cookie&rdquo; means a small file placed on your computer or
          device by our Sites when you visit certain parts of our Sites and/or
          when you use certain features of our Sites;
          <br />
          &ldquo;Cookie Law&rdquo; means the relevant parts of the Privacy and
          Electronic Communications (EC Directive) Regulations 2003 and of EU
          Regulation 2016/679 General Data Protection Regulation
          (&ldquo;GDPR&rdquo;);
          <br />
          &ldquo;Personal Data&rdquo; means any and all data that relates to an
          identifiable person who can be directly or indirectly identified from
          that data, as defined by EU Regulation 2016/679 General Data
          Protection Regulation (&ldquo;GDPR&rdquo;); and
          <br />
          &ldquo;We/Us/Our&rdquo; means DClickLimited, company no.02661520,
          whose registered office is: DClickHouse, Shrewsbury Business Park,
          Shrewsbury, SY2 6LG, United Kingdom (&ldquo;DClick&rdquo;).
          <br />
          2. Information About Us
        </p>

        <p>
          Our Sites owned and operated by us, DClick. If you would like to know
          more about how we use Cookies, please contact us at info@dclickltd.com
        </p>

        <p>
          3. How do we use Cookies?
          <br />
          Our Sites may place and access certain first party Cookies on your
          computer or device. First party Cookies are those placed directly by
          us and are used only by us. We use Cookies to facilitate and improve
          your experience of our Sites and to provide and improve our products
          and services. We have carefully chosen these Cookies and have taken
          steps to ensure that your privacy and personal data is protected and
          respected at all times.
        </p>

        <p>
          By using our Sites, you may also receive certain third-party Cookies
          on your computer or device. Third party Cookies are those placed by
          websites, services, and/or parties other than us. Third party Cookies
          are used on our Sites for website usage insights and advertising
          purposes. By collecting this data, we can continually improve the
          website experience. These Cookies are small text files which are
          stored on your computer by your web browser. They are not computer
          programs, and they can&rsquo;t read any other information saved on
          your hard drive. They can&rsquo;t spread viruses or obtain sensitive
          user information such as email addresses and credit card numbers.
        </p>

        <p>
          All Cookies used by and on our Sites are used in accordance with
          current Cookie Law. We may use some or all of the following types of
          Cookie:
        </p>

        <p>
          &middot; Strictly necessary Cookies
          <br />
          A Cookie falls into this category if it is essential to the operation
          of our Sites, supporting functions such as logging in, your shopping
          basket, and payment transactions.
          <br />
          &middot; Analytics Cookies
          <br />
          It is important for us to understand how you use our Sites, for
          example, how efficiently you are able to navigate around it, and what
          features you use. Analytics Cookies enable us to gather this
          information, helping us to improve our Sites and your experience of
          it.
          <br />
          &middot; Functionality Cookies
          <br />
          Functionality Cookies enable us to provide additional functions to you
          on our Sites such as personalisation and remembering your saved
          preferences. Some functionality Cookies may also be strictly necessary
          Cookies, but not all necessarily fall into that category.
          <br />
          &middot; Targeting Cookies
          <br />
          It is important for us to know when and how often you visit our Sites,
          and which parts of it you have used (including which pages you have
          visited, and which links you have visited). As with Analytical
          Cookies, this information helps us to better understand you and, in
          turn, to make our Sites and advertising more relevant to your
          interests. Some information gathered by targeting Cookies may also be
          shared with third parties.
          <br />
          &middot; Third Party Cookies
          <br />
          Third party Cookies are not placed by us; instead, they are placed by
          third parties that provide services to us and/or to you. Third party
          Cookies may be used by advertising services to serve up tailored
          advertising to you on our Sites, or by third parties providing
          analytical services to us (these Cookies will work in the same way as
          analytics Cookies described above).
          <br />
          &middot; Persistent Cookies
          <br />
          Any of the above types of Cookie may be a persistent Cookie.
          Persistent Cookies are those which remain on your computer or device
          for a pre-determined period and are activated each time you visit our
          Sites.
          <br />
          &middot; Session Cookies
          <br />
          Any of the above types of Cookie may be a session Cookie. Session
          Cookies are temporary and only remain on your computer or device from
          the point at which you visit our Sites until you close your browser.
          Session Cookies are deleted when you close your browser.
        </p>

        <p>
          Cookies on our Sites are not permanent and will expire as indicated in
          the table below.
        </p>

        <p>
          For more details of the personal data that we collect and use, the
          measures we have in place to protect personal data, your legal rights,
          and our legal obligations, please refer to our Privacy Policy.
        </p>

        <p>
          For more specific details of the Cookies that we use, please refer to
          the table below.
          <br />
          4. What Cookies does our Sites use?
          <br />
          The following third-party Cookies may be placed on your computer or
          device:
        </p>

        <p>
          Name of Cookie Purpose &amp; Type Provider
          <br />
          <br />
          DoubleClick
        </p>

        <p>
          These Cookies identify user sessions, passing information back to
          Google Analytics. With this information, we can improve the website
          experience.{" "}
          <a href="https://www.doubleclickbygoogle.com/">
            https://www.doubleclickbygoogle.com/
          </a>
          <br />
          <br />
          Google Tag Manager
        </p>

        <p>
          These Cookies identify user sessions, passing information back to
          Google Analytics. With this information, we can improve the website
          experience. Google.com
          <br />
          <br />
          Facebook
        </p>

        <p>
          This Cookie identifies user sessions, passing information back to
          Facebook&rsquo;s advertising platform. This allows us to communicate
          the latest offers and events via Facebook and Instagram ads.
          Facebook.com
          <br />
          Fullstory To track and improve the use of the website
          <a href="https://www.fullstory.com/">https://www.fullstory.com/</a>
          <br />
          Omniconvert Session cookies to provide specific web content to
          relevant users{" "}
          <a href="https://www.omniconvert.com/">
            https://www.omniconvert.com/
          </a>
          <br />
          Response Tap Adds unique phone numbers so that calls derived from the
          website can be tracked{" "}
          <a href="https://www.responsetap.com/">
            https://www.responsetap.com/
          </a>
          <br />
          Our Sites uses analytics services provided by &lt;&lt;Insert Name of
          Analytics Service Provider(s)&gt;&gt;.Google.com, Facebook.com and
          Responsetap.com. Website analytics refers to a set of tools used to
          collect and analyse anonymous usage information, enabling us to better
          understand how our Sites is used. This, in turn, enables us to improve
          our Sites and the products and services offered through it. You do not
          have to allow us to use these Cookies, however whilst our use of them
          does not pose any risk to your privacy or your safe use of our Sites,
          it does enable us to continually improve our Sites, making it a better
          and more useful experience for you.
        </p>

        <p>
          The analytics service used by our Sites use analytics Cookies to
          gather the required information. The analytics services used by our
          Sites uses the following analytics Cookies:
        </p>

        <p>
          Name of Cookie Purpose &amp; Type Provider
          <br />
          Universal Analytics These Cookies identify user sessions, passing
          information back to Google Analytics. With this information, we can
          improve the website experience.{" "}
          <a href="https://policies.google.com/privacy">
            https://policies.google.com/privacy
          </a>
          <br />
          Facebook Pixel This Cookie identifies user sessions, passing
          information back to Facebook&rsquo;s advertising platform. This allows
          us to communicate the latest offers and events via Facebook and
          Instagram ads.{" "}
          <a href="https://www.facebook.com/">https://www.facebook.com/</a>
          <br />
          Response Tap This Cookie identifies user sessions, passing information
          back to Response Tap&rsquo;s reporting platform. This allows us to see
          which page a user made a phone call from.{" "}
          <a href="https://www.responsetap.com/">
            https://www.responsetap.com/
          </a>
        </p>

        <p>5. DBM</p>

        <p>What Cookies do we use in our Direct Booking Manager?</p>

        <p>We use the following Cookies:</p>

        <p>
          Strictly necessary Cookies - We only need JSESSIONID in the OBM
          <br />
          Analytical/performance Cookies - They allow us to recognise and count
          the number of visitors and to see how visitors move around our website
          when they are using it. This helps us to improve the way our website
          works, for example, by ensuring that users are finding what they are
          looking for easily. These cookies are only used by DClickengineers and
          are only for internal use for tracking and reporting
          Analytical/performance and are ai_session and ai_user. They cannot be
          shared or accessed outside of DClick
          <br />
          Functionality Cookies - Not used by DClick
          <br />
          Targeting Cookies - Not used by DClick
          <br />
          Where can you find more information?
        </p>

        <p>
          You can find more information about the individual Cookies we use and
          the purposes for which we use them in the table below:
        </p>

        <p>
          Cookie Name Purpose More information
          <br />
          Java session JSESSIONID This Cookie will track the users purchase/s
          through the buying journey on the site. From initial product selection
          to final check out.
          <br />
        </p>

        <p>This Cookie is essential for our site to work.</p>

        <p>
          This Cookie contains no personal identifiable information it just
          contains a tag code to identify the user throughout the session. For
          example, as you add items to your shopping bag and move to the next
          screen it remembers what is in your bag.
          <br />
          Application Insights ai_session This just tracks an individual
          session. The Cookie will track the time a visitor is on a page and
          when they move to another page will identify them as the same user.
          <br />
          Application Insights ai_user This is a prevention mechanism Cookie.
          This Cookie monitors how many users are on the page or accessing the
          page at any one time. If there was a flood of activity, we would
          investigate as it may flag a blunt force attack.
          <br />
          6. Control
          <br />
          In addition to the controls that we provide, you can choose to enable
          or disable Cookies in your internet browser. Most internet browsers
          also enable you to choose whether you wish to disable all Cookies or
          only third-party Cookies. By default, most internet browsers accept
          Cookies but this can be changed.
        </p>

        <p>
          The links below provide instructions on how to control Cookies in all
          mainstream browsers: Google Chrome:{" "}
          <a href="https://support.google.com/chrome/answer/95647?hl=en-GB">
            https://support.google.com/chrome/answer/95647?hl=en-GB
          </a>
        </p>

        <p>
          Microsoft Internet Explorer: Delete and manage cookies (microsoft.com)
        </p>

        <p>
          Safari (macOS):{" "}
          <a href="https://support.apple.com/kb/PH21411?viewlocale=en_GB&locale=en_GB">
            https://support.apple.com/kb/PH21411?viewlocale=en_GB&locale=en_GB
          </a>
          Safari (iOS):{" "}
          <a href="https://support.apple.com/en-gb/HT201265">
            https://support.apple.com/en-gb/HT201265
          </a>
        </p>

        <p>
          Mozilla Firefox:{" "}
          <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences">
            https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences
          </a>
        </p>

        <p>
          Android:{" "}
          <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en">
            https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en
          </a>
        </p>

        <p>
          7. Changes to this Cookie Policy
          <br />
          We may alter this Cookie Policy at any time. Any such changes will
          become binding on you on your first use of our Sites after the changes
          have been made. You are therefore advised to check this page
          regularly.
        </p>

        <p>
          In the event of any conflict between the current version of this
          Cookie Policy and any previous versions, the current provisions in
          effect shall prevail unless it is expressly stated otherwise.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
