interface PartnerCardProps {
  image: string;
  title: string;
  body: string;
}
const PartnerCard = ({ image, title, body }: PartnerCardProps) => {
  return (
    <div className="bg-white p-[30px] rounded-[8px] flex gap-[30px] justify-between">
      <div className="w-fit">
      <div className="rounded-[8px] bg-[#EFEEE7] w-[70px] h-[70px] flex justify-center items-center">
        <img className="w-6 h-6" src={image} alt="" />
      </div>
      </div>
      <div className="w-full text-start">
        <h4 className="text-primary text-smm font-[500]">{title}</h4>
        <p className="text-subText w-[90%] mt-4">{body}</p>
      </div>
    </div>
  )
}

export default PartnerCard