import { FC, ReactNode } from "react";
import "./services-card.styles.scss";
import Icon from "../icon/icon";
import { Link } from "react-router-dom";
import RightArrow from "../right-arrow/right-arrow.component";

type Props = {
  cardTitle: string;
  cartDescription: ReactNode;
  iconFileName?: string;
  showLink?: boolean;
};

const ServicesCard: FC<Props> = ({
  cardTitle,
  cartDescription,
  iconFileName = "mvp",
  showLink = false,
}) => {
  return (
    <div className="services-card">
      <Icon name={iconFileName} />
      <hr />
      <h2 className="card-title">{cardTitle}</h2>
      <div className="cart-description">{cartDescription}</div>
      {showLink ? (
        <div className="services-card-link">
          <Link to="services">
            Check out{" "}
            <RightArrow/>
          </Link>
        </div>
      ) : null}
    </div>
  );
};

export default ServicesCard;
