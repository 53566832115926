import payPal from "../../assets/paypal.png";
import airbnb from "../../assets/Airbnb.png";
import shopify from "../../assets/shopify.png";
import yelp from "../../assets/yelp.png";
import megneto from "../../assets/Magento.webp";
import amazon from "../../assets/Amazon_icon.png";
export const partners = [
  {
    image: payPal,
    title: 'PayPal',
    body: 'Define each stage of work to see what’s important and where things are stuck.',
  },
  {
    image: airbnb,
    title: 'Airbnb',
    body: 'Define each stage of work to see what’s important and where things are stuck.',
  },
  {
    image: shopify,
    title: 'Shopify',
    body: 'Define each stage of work to see what’s important and where things are stuck.',
  },
  {
    image: yelp,
    title: 'Yelp',
    body: 'Define each stage of work to see what’s important and where things are stuck.',
  },
  {
    image: megneto,
    title: 'Magento',
    body: 'Define each stage of work to see what’s important and where things are stuck.',
  },
  {
    image: amazon,
    title: 'Amazon',
    body: 'Define each stage of work to see what’s important and where things are stuck.',
  },
];

