export const cardsArray = [
  {
    title: "MVP",
    description: (
      <div>
        DClick can play a vital role in helping you to develop and launch an MVP
        (minimum viable product). Here are a few ways DClick can help:
        <ul>
          <li>Define the MVP</li>
          <li>Determine the technology stack</li>
          <li>Develop the MVP</li>
          <li>Test and validate the MVP</li>
          <li>Iterate and improve the MVP</li>
        </ul>
      </div>
    ),
  },
  {
    title: "Mobile Development",
    description: (
      <div>
        DClick can help in Mobile Development in several ways:
        <ul>
          <li>Planning and Strategy</li>
          <li>Technology Selection</li>
          <li>User Experience Design</li>
          <li>Development and Testing</li>
          <li>Deployment and Maintenance</li>
        </ul>
      </div>
    ),
  },
  {
    title: "Product Management",
    description: (
      <div>
        DClick can play a crucial role in assisting with product management in
        several ways:
        <ul>
          <li>Market research</li>
          <li>Product strategy</li>
          <li>Product design</li>
          <li>Development</li>
          <li>Testing and quality assurance</li>
          <li>Deployment and maintenance</li>
        </ul>
      </div>
    ),
  },
  {
    title: "Product Discovery Process",
    description: (
      <div>
        DClick can play a crucial role in the product discovery process, by
        providing expertise and guidance to help you identify and develop
        successful products. Here are some ways DClick can help:
        <ul>
          <li>Market Research</li>
          <li>Idea Generation</li>
          <li>Prototyping</li>
          <li>User Testing</li>
          <li>Development</li>
        </ul>
      </div>
    ),
  },
  {
    title: "Quality Assurance ",
    description: (
      <div>
        DClick can play a critical role in ensuring the quality of software
        products through their quality assurance (QA) services. Here are some
        ways DClick can help in QA:
        <ul>
          <li>Planning</li>
          <li>Test Automation</li>
          <li>Manual Testing</li>
          <li>Performance Testing</li>
          <li>Security Testing</li>
        </ul>
      </div>
    ),
  },
  {
    title: "Web Development",
    description: (
      <div>
        DClick can help in web development in several ways. Here are some
        examples:
        <ul>
          <li>Technical expertise</li>
          <li>Project management</li>
          <li>Custom development</li>
          <li>Integration</li>
        </ul>
        
      </div>
    ),
  },
  {
    title: "Product Design",
    description: (
      <div>
        DClick can help in product design by providing expertise, guidance, and
        support throughout the entire design process. Here are some specific
        ways DClick can be helpful:
        <ul>
          <li>User research</li>
          <li>UX/UI design</li>
          <li>Technical evaluation </li>
          <li>Project management</li>
        </ul>
      </div>
    ),
  },
  {
    title: "User Testing",
    description: (
      <div>
        DClick can help with user testing in the following ways
        <ul>
          <li>Planning and designing</li>
          <li>Conducting the tests</li>
          <li>Analyzing the results</li>
          <li>Iterating and improving</li>
        </ul>
      </div>
    ),
  },
  {
    title: "Cloud Development and Migration",
    description: (
      <div>
        DClick can offer a range of services to help companies with cloud
        development and migration. Here are some ways in which DClick can help:
        <ul>
          <li>Cloud Strategy</li>
          <li>Infrastructure Design</li>
          <li>Migration Planning</li>
          <li>Cloud Development</li>
          <li>Cloud Management</li>
        </ul>
      </div>
    ),
  },
  {
    title: "Agile and Scrum Process",
    description: (
      <div>
        DClick can help your organization adopt Agile and Scrum processes in several ways, including:
        <ul>
          <li>Process Assessment</li>
          <li>Customized Training</li>
          <li>Coaching and Mentoring</li>
          <li>Implementation Planning</li>
          <li>Continuous Improvement</li>
        </ul>
      </div>
    ),
  },
];
