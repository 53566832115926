import TechnologyCard from "../technology-card/technology-card.component";
import "./technologies-section.styles.scss";

const cardsArray = [
  {
    iconFileName: "css",
    title: "Programming Languages",
    id: 1,
    description: "languages include Java, Python, C++, and JavaScript.",
  },
  {
    iconFileName: "backend",
    title: "Frameworks and Libraries",
    id: 2,
    description: "React, Angular, Node.js, and Django.",
  },
  {
    iconFileName: "app development",
    title: "Mobile Software Development",
    id: 3,
    description: "Kotlin, Swift, RWD, React Native, Flutter, PWA",
  },
  {
    iconFileName: "cloud computing",
    title: "Cloud Computing Platforms",
    id: 4,
    description: "Amazon Web Services (AWS), Microsoft Azure, and Google Cloud",
  },
  {
    iconFileName: "server",
    title: "Databases",
    id: 5,
    description: "MySQL, Oracle, MongoDB, and PostgreSQL.",
  },
  {
    iconFileName: "mobile app",
    title: "Devops",
    id: 6,
    description:
      "Azure/AWS/Google CS Multicloud / Cloud native, Docker, TerraForm Octopus/Puppet Bamboo/Jenkins/TFS Kubernetes",
  },
  {
    iconFileName: "backend",
    title: "AI & ML",
    id: 7,
    description:
      "Data Science/Machine Learning, Deep Learning, NLP, Tensor Flow, Keras, Pyro.ai, Infer.NET, Probabilistic programming",
  },
  {
    iconFileName: "mockup design",
    title: "Design",
    id: 8,
    description:
      "Sketch, Adobe Creative Suite, Figma, InVision, Balsamiq, Zeplin",
  },
];

const TechnologiesSection = () => {
  return (
    <div className="technologies-section">
      <div className="technologies-section-container">
        Technologies
        <div className="technologies-list">
          {cardsArray.map(({ iconFileName, id, title, description }) => (
            <TechnologyCard
              key={id}
              iconFileName={iconFileName}
              title={title}
              description={description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TechnologiesSection;
