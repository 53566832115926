import TopSectionLayout from "../../components/top-section-layout/top-section-layout.component";
import aboutUsHeaderImage from "../../assets/about-us-header.png";

import { cardsArray } from "./data";
import Card from "../../components/card/card.component";
import OurMissionSection from "../../components/our-mission-section/our-mission-section.component";
import ContactSection from "../../components/contact-section/contact-section.component";

import FadeIn from "../../components/fade-in/fade-in.component";
import "./about-us.styles.scss";

const AboutUs = () => {
  return (
    <div className="about-us">
      <TopSectionLayout></TopSectionLayout>
      <div className="about-us-body">
        <div className="about-us-header">
          <div className="about-us-header-description">
            <h2>Welcome to DClick,</h2>
            <p>
              The leading software company for all your digital product needs.
              At DClick, we are committed to providing innovative and
              user-friendly solutions that meet the ever-evolving needs of our
              clients. Our team of experienced developers and designers work
              tirelessly to create custom software solutions that help
              businesses thrive in today's digital landscape.
              <br /> Whether you're looking to create a web or mobile
              application, build an e-commerce platform, or streamline your
              business processes, DClick has the expertise to take your project
              from concept to launch. We pride ourselves on our attention to
              detail and our ability to deliver projects on time and within
              budget.
            </p>
          </div>
          <div className="about-us-header-image">
            <FadeIn>
              <img src={aboutUsHeaderImage} alt="about us header" />
            </FadeIn>
          </div>
        </div>
        <div className="about-us-container">
          <div className="about-us-title">
            <span>Dclick‘s values include</span>
            <h2>Meet our values..</h2>
          </div>
          <div className="about-us-card-list">
            {cardsArray.map(({ title, description }) => (
              <Card
                cardTitle={title}
                cardDescription={description}
                key={title}
              />
            ))}
          </div>
        </div>
        <OurMissionSection />
        <ContactSection />
      </div>
    </div>
  );
};

export default AboutUs;
