export const getMobileOperatingSystem = () => {
  const userAgent: string =
    navigator.userAgent ||
    navigator.vendor ||
    (window as any).opera ||
    undefined;
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return "iOS";
  }
  return "unknown";
};
