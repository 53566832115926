import TopSectionLayout from "../../../components/top-section-layout/top-section-layout.component";
import desk from "../../../assets/desk.png";
import deskHeader from "../../../assets/deskHeader.png";

const DeskScreens = () => {
  const DESK_BOOKING = [
    {
      title: "Check desk availability",
      desc: "Unsure if a desk is free for you to use? No problem. Clear LED status lights and a bright full-color display give you instant visibility of desk status, even from a distance.",
    },
    {
      title: "Free up unused space",
      desc: "Your work doesn’t have to be held back by no-shows and latecomers wasting desk space. Through Condeco desk screens, spaces can be released for use by someone else if a booker doesn’t check in within a pre-defined time-frame.",
    },
    {
      title: "Touch and go.",
      desc: "With an RFID-enabled access card, you can scan into any desk screen and book, check in or check out in a matter of seconds.",
    },
  ];

  return (
    <div className='bg-[#ebebec] pb-12'>
      <TopSectionLayout>
        <div className='hidden relative md:grid md:grid-cols-2 lg:grid-cols-3  grid-cols-1 gap-2 items-center mb-4 text-white'>
          <div className='lg:col-span-2 w-full pl-4'>
            <span className='sm:text-sm lg:text-smm pb-2 font-normal'>
              Desk Booking System
            </span>
            <h3 className='font-bold text-md lg:text-lg'>
              A screen at every desk.
            </h3>
            <p className='text-smm lg:text-md lg:w-4/5 font-normal'>
              d litora torquent per conubia nostra, per inceptos himenaeos.
              Praesent auctor purus luctus enim egestas, ac scelerisque ante
              pulvinar.
            </p>
          </div>
          <img
            src={deskHeader}
            alt='miro img'
            className='lg:mt-12 w-[600px] h-[400px]  mx-auto  lg:col-span-1'
          />
        </div>
      </TopSectionLayout>

      <div className='md:hidden relative mt-[-445px] w-11/12 lg:w-4/5 mx-auto mb-20'>
        <div className='mb-4 text-white'>
          <div className='pb-8 lg:pb-0  w-full pl-4'>
            <span className='sm:text-sm lg:text-smm  font-normal'>
              Desk Booking System
            </span>
            <h3 className='font-bold text-md lg:text-lg mt-4'>
              A screen at every desk.
            </h3>
            <p className='text-smm lg:text-md lg:w-4/5 font-normal'>
              d litora torquent per conubia nostra, per inceptos himenaeos.
              Praesent auctor purus luctus enim egestas, ac scelerisque ante
              pulvinar.
            </p>
          </div>
          <img
            src={deskHeader}
            alt='miro img'
            className='w-[435px] h-[242px]  mx-auto  mt-28 '
          />
        </div>
      </div>

      <section className='bg-white mt-12'>
        <div className='w-11/12 lg:w-4/5  mx-auto grid md:grid-cols-2  grid-cols-1 items-center gap-6 pb-8'>
          <img
            src={desk}
            alt='miro img'
            className='w-[335px] h-[335px] md:w-[468px] md:h-[459px]    mx-auto'
          />
          <div className='ml-12'>
            {DESK_BOOKING.map((item) => (
              <div key={item.title}>
                <h2 className='text-mainText text-smm lg:text-md font-medium mb-[22px] pt-[22px]'>
                  {item.title}
                </h2>
                <p className='text-subText lg:text-smm font-normal text-sm pb-[23px]'>
                  {item.desc}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default DeskScreens;
