import React, { FC, useRef, useEffect } from "react";
import "./fade-in.styles.scss";

type FadeInSectionProps = {
  children: React.ReactNode;
};

const FadeIn: FC<FadeInSectionProps> = ({ children }) => {
  const [isVisible, setVisible] = React.useState(true);
  const domRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    const divElement = domRef.current;
    if (divElement) {
      observer.observe(divElement);
    }
    return () => {
      if (divElement) observer.unobserve(divElement);
    };
  }, []);
  return (
    <div
      className={`fade-in ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {children}
    </div>
  );
};

export default FadeIn;
