import { FC } from "react";
import { useDynamicSVGImport } from "../../hooks/useDynamicSVGImport";

type Props = {
  name: string;
};

const Icon: FC<Props> = ({ name }) => {
  const { loading, ImportedIcon } = useDynamicSVGImport(name);

  if (!loading && ImportedIcon) {
    return <ImportedIcon className="icon" />;
  }
  return <div>Loading...</div>;
};

export default Icon;
