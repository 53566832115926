import { CheckIcon } from "@heroicons/react/24/solid"
interface IPoint {
  text: string;
  width?:number;
  className?: string;
}
const CheckPoint = ({ text, className, width = 23 }: IPoint) => {
  return (
    <li className="flex items-center gap-4">
      <CheckIcon width={width}
        className={className ?? "rounded-full bg-red text-white p-1 flex items-center justify-center"} />
      {text}
    </li>
  )
}

export default CheckPoint