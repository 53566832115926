import { Link } from "react-router-dom";
import image from "../../assets/Rectangle 25.png";
import RightArrow from "../right-arrow/right-arrow.component";
import FadeIn from "../fade-in/fade-in.component";
import "./identity-card.styles.scss";

const IdentityCard = () => {
  return (
    <div className="identity-card">
      <div className="identity-card-body">
        Who are we?
        <h2>We are an end-to-end custom product development company.</h2>
        <div className="identity-card-description">
          DClick offers a variety of services to startups and businesses. For
          startups and idea adapters, DClick can provide guidance on how to turn
          their ideas into successful products by leveraging its expertise in
          product design and user testing. The company's Scrum team augmentation
          services can help businesses scale up their development teams quickly
          and efficiently to meet project deadlines. Overall, DClick is
          well-positioned to offer consulting services to businesses that need
          help with technology strategy, product design, and software
          development. Its target audience includes startups and businesses that
          want to bring innovative products to market and need expert guidance
          throughout the product development lifecycle.
        </div>
        <div className="identity-card-link">
          <Link to="about-us">
            See more about us{" "}
            <span>
              <RightArrow color="var(--dClick-color-primary-third-blue)" />
            </span>
          </Link>
        </div>
      </div>
      <div className="identity-card-image">
        <FadeIn>
          <img src={image} alt="people working" />
        </FadeIn>
      </div>
    </div>
  );
};

export default IdentityCard;
