import "./contact-section.styles.scss";

const ContactSection = () => {
  return (
    <div id="contact" className="contact-section">
      <div className="contact-section-container">
        <div className="contact-form">
          <h2 className="contact-form-title">Contact us</h2>
          <p className="contact-form-description mb-10">
            Let's talk about your idea – fill out the form on the right and
            we'll get back to you shortly. We can't wait to start working with
            you!
          </p> 
          <a className="rounded-lg bg-red text-white py-3 px-5" href="mailto:info@dclickltd.com">send email</a>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
