import { FC } from "react";
import "./technology-card.styles.scss";
import Icon from "../icon/icon";

type Props = {
  iconFileName: string;
  title: string;
  description: string;
};

const TechnologyCard: FC<Props> = ({ iconFileName, title, description }) => {
  return (
    <div className="technology-card">
      <Icon name={iconFileName} />
      <div className="technology-card-title">{title}</div>
      <div className="technology-card-description">{description}</div>
    </div>
  );
};

export default TechnologyCard;
